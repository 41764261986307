import React, { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Warning from "../../src/assets/img/warning.svg";
import { Modal } from 'react-bootstrap';

import { dispatch } from "../redux";
import { deleteOrgUser } from "../redux/action";

function WarningDlt(Props) {
  const [show, setShow] = useState(Props.showData);

  const handleClose = () => {
    setShow(false)
    Props.parentCallback(false);
  }

const handleDelete =()=>{
 dispatch(deleteOrgUser(Props.id,Props.cmpId));
 handleClose();
}
  useEffect(() => {
    setShow(true);
  }, [Props.showData])

  return (
    <>
      {(Props.showData === true) ?

        <Modal show={show} onHide={handleClose} centered className="warning-modal modal" animation={false}>
          <Modal.Header className="align-items-center">
            <h3 className="modal-title">Warning title</h3>
            <button className="close" onClick={handleClose}>
              <img src={Warning} alt="Warning" width={45} />
            </button>
          </Modal.Header>
          {/* <Modal.Body> <p className="text-default font-size-12">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim </p></Modal.Body> */}
          <Modal.Footer className="justify-content-start">
            <Button variant="primary" onClick={handleDelete}>
              Confirm
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        : null}

    </>
  );
}

export default WarningDlt;
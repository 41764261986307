import React from "react";
import { Redirect, Route } from "react-router-dom";
import { validateToken } from "../utils";
import Header from "./header/header";
import Sidebar from "./sidebar/sidebar";

  // const [colortheme, setColortheme] = useState('#dc6868');

  function PrivateRoute({ component: Component, ...rest }) {
    return (
      <>
        <Route
          {...rest}
          render={(props) =>
            validateToken() ? (
              <>
               <div id="app" > 
                <Sidebar />
                <div className="content-wrapper">
                  <Header />
                  <Component {...props} />
                </div>
              </div>
            </>
            ) : (
              <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
          }
        />
      </>
    );
  }
// }

export default PrivateRoute;

import {apiCall, METHOD} from "../../service/baseApiCall";
import {
    SHIPMENTCOUNT,
    SHIPMENTLIST,
    SHIPMENTDETAILS,
    SHIPMENTPREVIEWDETAILS,
    SHIPMENTSTATUS,
    GENERALSEARCH,
    NOTIFICATIONCMP,
    COUNTRYFLAG,
    ATTACHMENTPDF,
    NOTIFICATIONLIST
} from "../constants/action-types";
import {toast} from "react-toastify";

//shipment count
export const shipmentCount = (val) => (dispatch) => {
    dispatch(shipmentCountInit(val));
};

export const shipmentCountInit = (val) => (dispatch) => {
    var values = Object.entries(val);
    dispatch({
        type: SHIPMENTCOUNT.SHIPMENTCOUNT_INITLIZATION
    });
    let url = new URL(process.env.REACT_APP_BASE_API_CALL + 'shipment/count');

    if (values) {
        values.forEach((i) => {  // Canviat de map a forEach
            i[1] && url.searchParams.append(i[0], i[1]);
        });
    }

    apiCall(
        url,
        {},
        (res) => dispatch(shipmentCountSuccess(res)),
        () => dispatch(shipmentCountError()),
        METHOD.GET,
        {}
    );
};

export const shipmentCountSuccess = (res) => (dispatch) => {
    // console.log(res.data,"SHIPMENT COUNT: ")
    dispatch({
        type: SHIPMENTCOUNT.SHIPMENTCOUNT_SUCCESS,
        payload: res?.data
    });
};

export const shipmentCountError = () => (dispatch) => {
    dispatch({
        type: SHIPMENTCOUNT.SHIPMENTCOUNT_ERORR
    });
};

//shipment list 
export const shipmentList = (val) => (dispatch) => {
    dispatch(shipmentListInit(val));
};

export const shipmentListInit = (val) => (dispatch) => {
    var values = Object.entries(val);
    dispatch({
        type: SHIPMENTLIST.SHIPMENTLIST_INITLIZATION
    });
    let url = new URL(process.env.REACT_APP_BASE_API_CALL + 'shipment/list');

    if (values) {
        values.forEach((i) => {
            i[1] && url.searchParams.append(i[0], i[1]);
        })
    }

    apiCall(
        url,
        {},
        (res) => dispatch(shipmentListSuccess(res)),
        () => dispatch(shipmentListError()),
        METHOD.GET,
        {}
    );
};

export const shipmentListSuccess = (res) => (dispatch) => {
    // console.log(res.data,"list")
    dispatch({
        type: SHIPMENTLIST.SHIPMENTLIST_SUCCESS,
        payload: res?.data
    });
};

export const shipmentListError = () => (dispatch) => {
    dispatch({
        type: SHIPMENTLIST.SHIPMENTLIST_ERORR
    });
};


//shipment Details
export const shipmentDetails = (id) => (dispatch) => {
    dispatch(shipmentDetailsInit(id));
};

export const shipmentDetailsInit = (id) => (dispatch) => {
    console.log('Init shipment details')
    dispatch({
        type: SHIPMENTDETAILS.SHIPMENTDETAILS_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `shipment/${id}`,
        {},
        (res) => dispatch(shipmentDetailsSuccess(res)),
        () => dispatch(shipmentDetailsError()),
        METHOD.GET,
        {}
    );
};

export const shipmentDetailsSuccess = (res) => (dispatch) => {
    console.log('Success shipment details', res?.data)
    dispatch({
        type: SHIPMENTDETAILS.SHIPMENTDETAILS_SUCCESS,
        payload: res?.data
    });
};

export const shipmentDetailsError = () => (dispatch) => {
    dispatch({
        type: SHIPMENTDETAILS.SHIPMENTDETAILS_ERORR
    });
};

//shipment Preview Details
export const shipmentPreviewDetails = (id) => (dispatch) => {
    dispatch(shipmentPreviewDetailsInit(id));
};

export const shipmentPreviewDetailsInit = (id) => (dispatch) => {
    dispatch({
        type: SHIPMENTPREVIEWDETAILS.SHIPMENTPREVIEWDETAILS_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `shipment/preview/${id}`,
        {},
        (res) => dispatch(shipmentPreviewDetailsSuccess(res)),
        () => dispatch(shipmentPreviewDetailsError()),
        METHOD.GET,
        {}
    );
};

export const shipmentPreviewDetailsSuccess = (res) => (dispatch) => {
    // console.log('shipment preview details success', res);
    dispatch({
        type: SHIPMENTPREVIEWDETAILS.SHIPMENTPREVIEWDETAILS_SUCCESS,
        payload: res?.data
    });
};

export const shipmentPreviewDetailsError = () => (dispatch) => {
    dispatch({
        type: SHIPMENTPREVIEWDETAILS.SHIPMENTPREVIEWDETAILS_ERORR
    });
};

//shipment status
export const shipmentStatus = (val) => (dispatch) => {
    // console.log('ARRIBEM A VAL STATUS', val)
    dispatch(shipmentStatusInit(val));
  };
  
  export const shipmentStatusInit = (val) => (dispatch) => {
    // console.log('ARRIBEM A SHPMENT STATUS INIT', val)
    dispatch({
        type: SHIPMENTSTATUS.SHIPMENTSTATUS_INITLIZATION
    });

    let url = new URL(process.env.REACT_APP_BASE_API_CALL + `shipment/status`);

    // console.log('FILTERS SHIPMENT STATUS', val);
    var values= Object.entries(val);
    if(values){
      values.forEach((i)=>{
        i[1] && url.searchParams.append(i[0], i[1]);
      })
    }
    // console.log('URL', url)
    apiCall(
        url
        ,
        {},
        (res) => dispatch(shipmentStatusSuccess(res)),
        () => dispatch(shipmentStatusError()),
        METHOD.GET,
        {}
    );
};

export const shipmentStatusSuccess = (res) => (dispatch) => {
    // console.log(res,"res")
    dispatch({
        type: SHIPMENTSTATUS.SHIPMENTSTATUS_SUCCESS,
        payload: res?.data
    });
};

export const shipmentStatusError = () => (dispatch) => {
    dispatch({
        type: SHIPMENTSTATUS.SHIPMENTSTATUS_ERORR
    });
};

//General Search
export const GeneralSearch = (text) => (dispatch) => {
    text && dispatch(GeneralSearchInit(text));
};

export const GeneralSearchInit = (text) => (dispatch) => {
    dispatch({
        type: GENERALSEARCH.GENERALSEARCH_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `header/search/${text}`,
        {},
        (res) => dispatch(GeneralSearchSuccess(res)),
        () => dispatch(GeneralSearchError()),
        METHOD.GET,
        {}
    );
};

export const GeneralSearchSuccess = (res) => (dispatch) => {
    // console.log(res.data,"list")
    dispatch({
        type: GENERALSEARCH.GENERALSEARCH_SUCCESS,
        payload: res?.data
    });
};

export const GeneralSearchError = () => (dispatch) => {
    dispatch({
        type: GENERALSEARCH.GENERALSEARCH_ERORR
    });
};

//Home Notification 
export const getNotificationsColumn = () => (dispatch) => {
  dispatch(NotificationCmpInit());
};

export const NotificationCmpInit = () => (dispatch) => {
  dispatch({
    type: NOTIFICATIONCMP.NOTIFICATIONCMP_INITLIZATION
  });
  apiCall(
    process.env.REACT_APP_BASE_API_CALL + `notification/preview`,
    {
    },
    (res) => dispatch(NotificationCmpSuccess(res)),
    () =>  dispatch(NotificationCmpError()) ,
    METHOD.GET,
    {}
  );
};

export const NotificationCmpSuccess = (res) => (dispatch) => {
  // console.log(res.data,"list")
  dispatch({
    type: NOTIFICATIONCMP.NOTIFICATIONCMP_SUCCESS ,
    payload: res?.data
  });
};

export const NotificationCmpError = () => (dispatch) => {
  dispatch({
    type: NOTIFICATIONCMP.NOTIFICATIONCMP_ERORR
  });
};

//country flag

export const countryFlag = () => (dispatch) => {
    dispatch(countryFlagInit());
};

export const countryFlagInit = () => (dispatch) => {
    dispatch({
        type: COUNTRYFLAG.COUNTRYFLAG_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `reports/report1`,
        {},
        (res) => dispatch(countryFlagSuccess(res)),
        () => dispatch(countryFlagError()),
        METHOD.GET,
        {}
    );
};

export const countryFlagSuccess = (res) => (dispatch) => {
    dispatch({
        type: COUNTRYFLAG.COUNTRYFLAG_SUCCESS,
        payload: res?.data
    });
};

export const countryFlagError = () => (dispatch) => {
    dispatch({
        type: COUNTRYFLAG.COUNTRYFLAG_ERORR
    });
};

//Notification List


export const NotificationList = (bData,text) => (dispatch) => {
  dispatch(NotificationListInit(bData,text));
};

export const NotificationListInit = (bData,text) => (dispatch) => {
  var values= Object.entries(text);

  if(text){
    // console.log(text,"sh");
  }
  dispatch({
    type: NOTIFICATIONLIST.NOTIFICATIONLIST_INITLIZATION
  });

  let url = new URL(process.env.REACT_APP_BASE_API_CALL + `notification/list?isactionrequired=${bData}`);
  if(values){
    values.forEach((i)=>{
      i[1] && url.searchParams.append(i[0], i[1]);
    })
  }
  apiCall(
    url,
    {
    },
    (res) => dispatch(NotificationListSuccess(res)),
    () =>  dispatch(NotificationListError()) ,
    METHOD.GET,
    {}
  );
};

export const NotificationListSuccess = (res) => (dispatch) => {
  dispatch({
    type: NOTIFICATIONLIST.NOTIFICATIONLIST_SUCCESS ,
    payload: res?.data
  });
};

export const NotificationListError = () => (dispatch) => {
  dispatch({
    type: NOTIFICATIONLIST.NOTIFICATIONLIST_ERORR
  });
};

//download attachment pdf
export const previewPDF = (id, scope) => (dispatch) => {
    dispatch(previewPdfInit(id, scope));
};

export const previewPdfInit = (id, scope) => (dispatch) => {
    dispatch({
        type: ATTACHMENTPDF.ATTACHMENTPDF_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `shipment/preview/${scope}/${id}`,
        {},
        (res) => dispatch(previewPdfSuccess(res)),
        () => dispatch(previewPdfError()),
        METHOD.GET,
        {}
    );
};

export const previewPdfSuccess = (res) => (dispatch) => {
    dispatch({
        type: ATTACHMENTPDF.ATTACHMENTPDF_SUCCESS,
        payload: res?.data
    });
    toast.success('El documento se ha previsualizado con éxito', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "light",
    });
};

export const previewPdfError = () => (dispatch) => {
    dispatch({
        type: ATTACHMENTPDF.ATTACHMENTPDF_ERORR
    });
    toast.error('Error al previsualizar el documento', {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "light",
    });
};

import React from "react";
import { Card, Breadcrumb, Button, Row, Col, Form } from "react-bootstrap";
import { ReactComponent as BackIcon } from '../../assets/img/back.svg'
import { ReactComponent as ArrowDownIcon } from "../../assets/img/arrow-down.svg";
import { NeedHelpBox } from "../../components";
import {useTranslation} from "react-i18next";

const NotificationsCreate = () => {

    const {t} = useTranslation()

    return (
      <React.Fragment>
        <div className="content">
            <Breadcrumb className='hot-shipment-action'>
                <Breadcrumb.Item className='mr-3'> <BackIcon className="back-icon" /> </Breadcrumb.Item>
                
                <div className='arrow-down d-inline-block ml-auto mr-2'>
                    <Form.Control as="select">
                        <option>Actions</option>
                        <option>Option 1</option>
                        <option>Option 2</option>
                        <option>Option 3</option>
                        <option>Option 4</option>
                        <option>Option 5</option>
                    </Form.Control>
                    <ArrowDownIcon className="arrow-down-icon" />
                </div>                   
                <Button type="button" variant="primary">CREATE</Button>
            </Breadcrumb>
            <section className="page-content container-fluid">
                <h1 className="page-title">{t('Notifications')}</h1>
                <Row className='form-row'>
                    <Col className='col-lg-6'>
                        <Card className="rounded-0">
                            <Card.Header className='bg-primary-custom d-flex align-items-center h-auto rounded-0 px-5 py-3'>
                                <h4 className='text-white font-weight-normal m-0'>SE210045 BCN-VLC</h4>
                                <div className="ml-auto">
                                    <h4 className='text-white font-weight-normal text-uppercase m-0'>Pending</h4>
                                </div>
                            </Card.Header>
                            <Card.Body className="common-details text-dark px-5 py-4">
                                <p className="font-weight-bold mb-4">Help is on the way! Please, click on the module of your enquiry so we can speed our assistance </p>
                                <Form.Group className="arrow-down mb-4">
                                    <Form.Label className="font-size-13 font-weight-bold">Referencia</Form.Label>
                                    <Form.Control as="select">
                                        <option>ALL</option>
                                        <option selected>SE210045 BCN-VLC</option>
                                        <option>SE210045 BCN-VLC</option>
                                        <option>SE210045 BCN-VLC</option>
                                        <option>SE210045 BCN-VLC</option>
                                    </Form.Control>
                                    <ArrowDownIcon className="arrow-down-icon" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label className="font-size-13 font-weight-bold">Summary</Form.Label>
                                    <Form.Control type="text" placeholder="Pending EUR1 for customs clearance" />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label className="font-size-13 font-weight-bold">Description</Form.Label>
                                    <Form.Control as="textarea" rows={6} />
                                </Form.Group>
                                <Form.Group className="mb-4 drag-drop-attachment">
                                    <Form.Label className="font-size-13 font-weight-bold d-block">Attachment</Form.Label>
                                    <div className="fileinput text-center fileinput-new position-relative d-block" data-provides="fileinput">
                                        <div className="btn-file">
                                            <div className="thumbnail fileinput-new uploaded-user-image font-size-15 text-primary-custom font-weight-bold" style={{height: '104px', width: '100%' }}>
                                                Drag and drop files, paste screenshots, or browse <br />
                                                <Button type="button" variant="secondary" className="fileinput-new btn-file text-primary-custom mt-2">Browse</Button>
                                            </div>
                                            <div className="clearfix"></div>
                                            <input type="hidden" value="" name="..." />
                                            <input type="hidden" value="" name="Users[image]" /><input type="file" file-model="myFile" name="" accept="" />
                                            <div className="fileinput-preview fileinput-exists thumbnail uploaded-user-image" height="104"></div>
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-link btn-sm fileinput-exists close-icon" data-dismiss="fileinput"> 
                                                <i className="zmdi zmdi-close text-white m-0"></i>
                                            </button>
                                        </div>
                                    </div>
                                </Form.Group>
                                <div>
                                    <Button type="button" variant="primary">Send</Button>
                                    <Button type="button" variant="secondary" className="m-l-10">Cancel</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='form-row'>
                    <Col xl={10}>
                        <Row className='form-row'>
                            <Col md={4} className="ml-auto">
                                <NeedHelpBox />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </div>
      </React.Fragment>
    )
}

export default NotificationsCreate;
import React, { useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import PropTypes from "prop-types";

const PaginationComp = ({
                          itemsCount,
                          itemsPerPage,
                          currentPage,
                          setCurrentPage,
                          alwaysShown = true
                        }) => {

  const pagesCount = Math.ceil(itemsCount / itemsPerPage);
  //console.log("pagesCount: ", pagesCount)
  const isPaginationShown = alwaysShown ? true : pagesCount > 1;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  const changePage = number => {
    //console.log("changePage")
    //console.log("currentPage: ", currentPage)
    //console.log("number: ", number)
    if (currentPage === number) return;
    setCurrentPage(number);
  };

  const onPageNumberClick = pageNumber => {
    //console.log("onPageNumberClick")
    //console.log("pageNumber: ", pageNumber)
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    //console.log("onPreviousPageClick")
    //console.log("currentPage", currentPage)
    changePage(currentPage => currentPage - 1);
  };

  const onNextPageClick = () => {
    //console.log("onNextPageClick")
    //console.log("currentPage", currentPage)
    changePage(currentPage => currentPage + 1);
  };

  const setLastPageAsCurrent = () => {
    //console.log("setLastPageAsCurrent")
    if (currentPage > pagesCount) {
      // console.log(pagesCount,"pagesCountpagesCount",currentPage,"currentPage",pagesCount,"pagesCount")
      // setCurrentPage(pagesCount);
    }
  };

  let isPageNumberOutOfRange;

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers =
        Math.abs(pageNumber - currentPage) <= 2;

    if (
        isPageNumberFirst ||
        isPageNumberLast ||
        isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
          <Pagination.Item
              key={pageNumber}
              onClick={() => onPageNumberClick(pageNumber)}
              active={pageNumber === currentPage}
          >
            {pageNumber}
          </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  useEffect(setLastPageAsCurrent, [pagesCount]);

  return (
      <>
        {isPaginationShown && (
            <Pagination size="sm" className='pt-2 mt-4 justify-content-end'>
              <Pagination.Prev
                  onClick={onPreviousPageClick}
                  disabled={isCurrentPageFirst}
              />
              {pageNumbers}
              <Pagination.Next
                  onClick={onNextPageClick}
                  disabled={isCurrentPageLast}
              />
            </Pagination>
        )}
      </>
  );
};

PaginationComp.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  alwaysShown: PropTypes.bool
};

export default PaginationComp;


import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = () => {
    return (
        <div className="container">
            <div className="loader"></div>
            <p className="loading-text">Loading...</p>
        </div>
    );
};


export default LoadingSpinner;

import React, { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Warning from "../../src/assets/img/warning.svg";
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import cookies from 'js-cookie';
import { dispatch } from "../redux";
import { logout } from "../redux/action";
import {resetInvoiceFilters} from "../redux/reducers/invoiceFiltersReducer";
import {resetShipmentFilters} from "../redux/reducers/shipmentFiltersReducer";
import {resetQuotationFilters} from "../redux/reducers/quotationFiltersReducer";

function Logout(Props) {
    const [show, setShow] = useState(Props.showData);
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const { t } = useTranslation()
    const handleClose = () => {
        setShow(false)
        Props.parentCallback(false);
    }

    const handleConfirm =()=>{
        dispatch(resetShipmentFilters())
        dispatch(resetInvoiceFilters())
        dispatch(resetQuotationFilters())
        dispatch(logout())
        setShow(false);
        Props.parentCallback(false);
    }
    useEffect(() => {
        setShow(true);
    }, [Props.showData])

    useEffect(() => {
        document.body.dir = currentLanguageCode.dir || 'ltr'
    }, [currentLanguageCode, t])
    
    return (
        <>
            {(Props.showData === true) ?

                <Modal show={show} onHide={handleClose} centered className="warning-modal" animation={false}>
                    {/* <Modal centered show={show} onHide={handleClose} className="warning-modal"> */}
                    <Modal.Header className="align-items-center">
                        <h3 className="modal-title">{t('Are_you_sure_want_to_exit?')}</h3>
                        <button className="close" onClick={handleClose}>
                            <img src={Warning} alt="Warning" width={45} />
                        </button>
                    </Modal.Header>
                    {/* <Modal.Body>
                        <p className="text-default font-size-12">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim </p>
                    </Modal.Body> */}
                    <Modal.Footer className="justify-content-start">
                        <Button variant="primary" onClick={handleConfirm}>
                            {t('Confirm')}
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                : null}

        </>
    );
}

export default Logout;


import React, { useEffect, useState } from "react";
import { Card, Breadcrumb, Button, Row, Col, Table, Form } from "react-bootstrap";
import { ReactComponent as BackIcon } from '../../assets/img/back.svg'
import { ReactComponent as SearchIcon } from '../../assets/img/search-icon.svg'
import { ReactComponent as SortIcon } from '../../assets/img/sort.svg'
import { ReactComponent as SortPrimaryIcon } from '../../assets/img/sort-primary.svg'
import { ReactComponent as Bell } from '../../assets/img/bell.svg';
import { ReactComponent as BellPrimaryBorder } from '../../assets/img/bell-primary-border.svg';
import { NeedHelpBox } from "../../components";
import PaginationComp from "../../components/pagination/PaginationComp";
import { useHistory } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { NotificationList } from "../../redux/action/notificationActions";
import { UploadNotifications } from "../../modals/UploadNotifications";
import {useTranslation} from "react-i18next";

const initFilterState = {
    text: '',
};
const Notifications = () => {
    const [sessionsPerPage, ] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState();
    const [notification, setNotification] = useState([]);
    const [filters, setfilters] = useState(initFilterState);
    const [data, ] = useState('false');
    const [showM, setShowM] = useState(false);

    const [searchtext, setsearchText] = useState('');

    const NotificationListData = useSelector((state) => state?.shipmentReducer?.notificationList);
   
    const allSessionsCount = notification?.length;
    const lastSessionNumber = currentPage * sessionsPerPage;
    const firstSessionIndex = lastSessionNumber - sessionsPerPage;
    const limitedSessions = notification?.slice(
        firstSessionIndex,
        lastSessionNumber
    );
    useEffect(() => {
        dispatch(NotificationList(data, filters));
    }, [data, filters])

    useEffect(() => {
        if (NotificationListData) {
            setNotification(NotificationListData)
        }
    }, [NotificationListData])

    const history = useHistory()

    /*const handleNotification = (e) => {
        setdata(e);
    }*/

    const handleSearch = (text) => {
        setsearchText(text);
        setfilters({ ...filters, text: text })
    }
    const handleRefresh = () => {
        setfilters(initFilterState);
        setsearchText('');
    }

    const handleCallback = () => setShowM(false);
    /*const handleShow =()=> setShowM(true);*/
    const handleShowNot =(id)=>{
        setId(id);
        setShowM(true);
    }

    const { t } = useTranslation()

    return (
        <React.Fragment>
            <div className="content">
                <Breadcrumb className='hot-shipment-action'>
                    <Breadcrumb.Item className='mr-3' onClick={history.goBack}> <BackIcon className="back-icon" /> </Breadcrumb.Item>    
                </Breadcrumb>
                <section className="page-content container-fluid">
                    <Row className='form-row'>
                        <Col xl={10}>
                            <Card>
                                <Card.Body>
                                    <Card.Body className='p-0'>
                                        <Card className='mb-0'>
                                            <div className='card-body'>
                                                <Row className='mb-3 align-items-center'>
                                                    <Col lg={8}>
                                                        <h1 className="page-title">{t('Notifications')}</h1>
                                                {/*        <ToggleButtonGroup type="radio" name="options" defaultValue='false' className="dashboard-filter latest-activity" value={data}*/}
                                                {/*            onChange={(e) => handleNotification(e)}>*/}
                                                {/*            <ToggleButton id="tbg-radio-1" variant="" value='false'>*/}
                                                {/*                {t('All')}*/}
                                                {/*            </ToggleButton>*/}
                                                {/*            <ToggleButton id="tbg-radio-2" variant="" value='true'>*/}
                                                {/*                <Bell /> {t('Require_your_Action')}*/}
                                                {/*            </ToggleButton>*/}
                                                {/*        </ToggleButtonGroup>*/}
                                                    </Col>
                                                    <Col lg={4} className='d-flex justify-content-lg-end mt-2 mt-lg-0'>
                                                        <div className='table-top-search d-flex mr-3'>
                                                            <SearchIcon className="icon" />
                                                            <Form.Control type="text" placeholder={t('Search')} value={searchtext} onChange={(e) => handleSearch(e.target.value)} />
                                                        </div>
                                                        <span className="refresh-icon" onClick={handleRefresh}>
                                                            <i className="zmdi zmdi-refresh font-size-18 align-middle"></i>
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Table responsive borderless hover>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th className="text-primary-custom">{t('Reference')} <SortPrimaryIcon className='sort-primary-icon align-baseline' /></th>
                                                            <th>{t('Creation_date')}  <SortIcon className='align-baseline' /></th>
                                                            {/*<th>{t('Sender')} <SortIcon className='align-baseline' /></th>*/}
                                                            <th>{t('checked')} <SortIcon className='align-baseline' /></th>
                                                            <th>{t('Severity')} <SortIcon className='align-baseline' /></th>
                                                            <th>{t('Subject')} <SortIcon className='align-baseline' /></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {limitedSessions && limitedSessions.map((item) => (
                                                            <tr key={item.id} onClick={() =>  {history.push({pathname: "/seafright/" + item?.shipmentId})}}>
                                                                <th >{!item.checked? <Bell className="bell-icon" /> : <BellPrimaryBorder className="bell-primary-icon" />}</th>
                                                                <td ><strong>{item.reference}</strong></td>
                                                                <td>{item.creationDate}</td>
                                                                {/*<td>{item.responsable}</td>*/}
                                                                <td>{item.checkedDate}</td>
                                                                <td>{item.severity}</td>
                                                                <td>{item.description}</td>
                                                                <td> {item.upload === true ? <>
                                                                    <Button type="button" variant="primary" size="sm" className="h-20 py-0 px-2" onClick={()=>handleShowNot(item.id)}>{t('Upload')}</Button>
                                                                    <input id='selectImage' hidden type="file" />
                                                                </> : null}
                                                                </td>
                                                            </tr>
                                                        ))}


                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card>
                                        <PaginationComp
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            alwaysShown={false}
                                        />
                                    </Card.Body>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='form-row'>
                        <Col xl={10}>
                            <Row className='form-row'>
                                <Col md={4} className="ml-auto">
                                    <NeedHelpBox />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            </div>
            {(id)?<UploadNotifications showData={showM} parentCallback={handleCallback} id={id}/>:null}
            
        </React.Fragment>
    )
}

export default Notifications;

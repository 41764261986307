import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect,
} from "react-router-dom";
import {publicRoutes, privateRoutes} from "./routeConstants";
import PrivateRoute from "../components/securedRoutes";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";


export default function Routes() {
    const isLoggedout = useSelector((state) => state.userDataReducer.isLoggedout);
    return (
        <>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                closeButton={false}
                pauseOnHover/>

            <Router basename={process.env.PUBLIC_URL}>
                <Switch>
                    {publicRoutes.map((item, index) => (
                        <Route key={index} {...item} />
                    ))}
                    {/*publicRoutes.map((item, index) => (console.log(item)))*/}
                    {isLoggedout ? <Redirect to="/"/>
                        :
                        (<>
                            {privateRoutes.map((item, index) => (

                                <PrivateRoute key={index} {...item} />

                            ))}
                        </>)}
                    <Route render={() => <Redirect to="/sign-in"/>}/>
                </Switch>
            </Router>
        </>
    );
}

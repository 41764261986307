import {apiCall, METHOD} from "../../service/baseApiCall";
import {
    INVOICELIST,
    INVOICEPDF,
    INVOICEFILTER,
    INVOICECOUNT,
    INVOICESTATUS,
} from "../constants/action-types";
import {toast} from "react-toastify";

//Invoice count
export const invoiceCount = (val) => (dispatch) => {
    dispatch(invoiceCountInit(val));
};

export const invoiceCountInit = (val) => (dispatch) => {
    var values= Object.entries(val);
    dispatch({
        type: INVOICECOUNT.INVOICECOUNT_INITLIZATION
    });
    let url = new URL(process.env.REACT_APP_BASE_API_CALL + 'invoice/count');
    if(values){
        values.forEach((i)=>{
            i[1] && url.searchParams.append(i[0], i[1]);
        })
    }
    apiCall(
        url,
        {
        },
        (res) => dispatch(invoiceCountSuccess(res)),
        () =>  dispatch(invoiceCountError()) ,
        METHOD.GET,
        {}
    );
};

export const invoiceCountSuccess = (res) => (dispatch) => {
    dispatch({
        type: INVOICECOUNT.INVOICECOUNT_SUCCESS ,
        payload: res?.data
    });
};

export const invoiceCountError = () => (dispatch) => {
    dispatch({
        type: INVOICECOUNT.INVOICECOUNT_ERORR
    });
};

//invoice list 
export const invoiceList = (val) => (dispatch) => {
    dispatch(invoiceListInit(val));
};

export const invoiceListInit = (val) => (dispatch) => {
    var values = Object.entries(val);
    dispatch({
        type: INVOICELIST.INVOICELIST_INITLIZATION
    });
    let url = new URL(process.env.REACT_APP_BASE_API_CALL + 'invoice/list');

    if (values) {
        values.forEach((i) => {
            i[1] && url.searchParams.append(i[0], i[1]);
        })
    }

    apiCall(
        url,
        {},
        (res) => dispatch(invoiceListSuccess(res)),
        () => dispatch(invoiceListError()),
        METHOD.GET,
        {}
    );
};

export const invoiceListSuccess = (res) => (dispatch) => {
    dispatch({
        type: INVOICELIST.INVOICELIST_SUCCESS,
        payload: res?.data
    });
};

export const invoiceListError = () => (dispatch) => {
    dispatch({
        type: INVOICELIST.INVOICELIST_ERORR
    });
};

//invoice status
export const invoiceStatus = (val) => (dispatch) => {
    dispatch(invoiceStatusInit(val));
};

export const invoiceStatusInit = (val) => (dispatch) => {
    dispatch({
        type: INVOICESTATUS.INVOICESTATUS_INITLIZATION
    });

    let url = new URL(process.env.REACT_APP_BASE_API_CALL + `invoice/status`);

    var values= Object.entries(val);
    if(values){
        values.forEach((i)=>{
            i[1] && url.searchParams.append(i[0], i[1]);
        })
    }
    apiCall(
        url
        ,
        {

        },
        (res) => dispatch(invoiceStatusSuccess(res)),
        () =>  dispatch(invoiceStatusError()),
        METHOD.GET,
        {}
    );
};

export const invoiceStatusSuccess = (res) => (dispatch) => {
    dispatch({
        type: INVOICESTATUS.INVOICESTATUS_SUCCESS,
        payload: res?.data
    });
};

export const invoiceStatusError = () => (dispatch) => {
    dispatch({
        type: INVOICESTATUS.INVOICESTATUS_ERORR
    });
};

//invoice filter type

export const invoiceFilter = () => (dispatch) => {
    dispatch(invoiceFilterInit());
};

export const invoiceFilterInit = () => (dispatch) => {
    dispatch({
        type: INVOICELIST.INVOICELIST_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + "invoice/status",
        {},
        (res) => dispatch(invoiceFilterSuccess(res)),
        () => dispatch(invoiceFilterError()),
        METHOD.GET,
        {}
    );
};

export const invoiceFilterSuccess = (res) => (dispatch) => {
    dispatch({
        type: INVOICEFILTER.INVOICEFILTER_SUCCESS,
        payload: res?.data
    });
};

export const invoiceFilterError = () => (dispatch) => {
    dispatch({
        type: INVOICEFILTER.INVOICEFILTER_ERORR
    });
};

//invoice pdf 
export const invoicePDFDnld = (id) => (dispatch) => {
    console.log('id pdf', id);
    dispatch(invoicePDFInit(id));
};

export const invoicePDFInit = (id) => (dispatch) => {
    console.log('init', id)
    dispatch({
        type: INVOICEPDF.INVOICEPDF_INITLIZATION
    });
    apiCall(
        process.env.REACT_APP_BASE_API_CALL + `invoice/${id}`,
        {},
        (res) => dispatch(invoicePDFSuccess(res)),
        () => dispatch(invoicePDFError()),
        METHOD.GET,
        {}
    );
};

export const invoicePDFSuccess = (res) => (dispatch) => {
    console.log('preview success', res)
    dispatch({
        type: INVOICEPDF.INVOICEPDF_SUCCESS,
        payload: res?.data
    });
};

export const invoicePDFError = () => (dispatch) => {
    dispatch({
        type: INVOICEPDF.INVOICEPDF_ERORR
    });
    toast.error("En estos momentos no se puede descargar el documento.", {
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};
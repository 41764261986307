import React from "react";
import { useFormik } from "formik";
import MainLogo from "../../components/logos/MainLogo";
import { Form } from "react-bootstrap";
import { ReactComponent as ArrowDownIcon } from "../../assets/img/arrow-down.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RegistrationSchema } from "../../validationSchema/validationSchema";
//redux
import { registration } from "../../redux/action";
import {useTranslation} from "react-i18next";

const Register = () => {

    const { push } = useHistory();
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            email: "",
            mobileNumber: "",
            DateF: "DD-MM-YYYY",
            lang: "Spanish",
            securityCode: "",
        },
        validationSchema: RegistrationSchema,
        onSubmit: (values) => {
            console.log('VALUES', values);
            dispatch(registration(values.firstName, values.lastName, values.password, values.email, values.mobileNumber, values.DateF, values.lang, values.securityCode));
            push("/")
        },
    });
    const { errors, touched } = formik;
    return (
        <React.Fragment>
            <div className="main-container d-flex align-items-center justify-content-center">
                <div className="login-box register-box common-details">
                    <div className="login-logo">
                        {/*<img src={LogoWhite} alt="Logo" height={32} />*/}
                        <MainLogo/>
                    </div>
                    <div className="login-box-body">
                        <h1 className="text-center mb-4 font-weight-600">{t('Registration')}</h1>
                        <form>
                            <div className="form-group">
                                <label>{t('First_Name')}</label>
                                <input type="text" className="form-control" placeholder="ex.: John" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                    name="firstName" />
                                {errors.firstName && touched.firstName && (
                                    <small className="text-danger" id="errUserName" >{errors.firstName}</small>
                                )}
                            </div>

                            <div className="form-group">
                                <label>{t('Last_Name')}</label>
                                <input type="text" className="form-control" placeholder="ex.: Garcia" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                    name="lastName" />
                                {errors.lastName && touched.lastName && (
                                    <small className="text-danger" id="errUserName" >{errors.lastName}</small>
                                )}
                            </div>

                            <div className="form-group">
                                <label>{t('Password')}</label>
                                <input type="password" className="form-control" placeholder={t('Password')} onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    name="password"
                                    id="txtPass" autoComplete="false" />

                                {errors.password && touched.password && (
                                    <small className="text-danger">{errors.password}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <label>{t('Confirm_password')}</label>
                                <input type="password" className="form-control" placeholder={t('Confirm_password')} onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                    name="confirmPassword"
                                    id="cPass" autoComplete="false" />
                                {errors.confirmPassword && touched.confirmPassword && (
                                    <small className="text-danger">{errors.confirmPassword}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className="form-control" placeholder="ex.: john@gmail.com" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    name="email" />
                                {errors.email && touched.email && (
                                    <small className="text-danger">{errors.email}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <label>{t('Phone')}</label>
                                <input type="text" className="form-control" placeholder="ex.: +34 9876543210" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.mobileNumber}
                                    name="mobileNumber" />
                                {errors.mobileNumber && touched.mobileNumber && (
                                    <small className="text-danger">{errors.mobileNumber}</small>
                                )}
                            </div>

                            <div className="form-group arrow-down">
                                <Form.Control as="select" onChange={formik.handleChange}
                                              name="DateF">
                                    <option>{t('Data_Format')}</option>
                                    <option value={"MM-DD-YYYY"}>MM-DD-YYYY</option>
                                    <option value={"DD-MM-YYYY"}>DD-MM-YYYY</option>
                                </Form.Control>
                                <ArrowDownIcon className="arrow-down-icon" />
                                {errors.DateF && touched.DateF && (
                                    <small className="text-danger">{errors.DateF}</small>
                                )}
                            </div>

                            <div className="form-group arrow-down">
                                <Form.Control as="select" onChange={formik.handleChange} name="lang">
                                    <option>{t('Language')}</option>
                                    <option value={"Spanish"}>Spanish</option>
                                    <option value={"English"}>English</option>
                                </Form.Control>
                                <ArrowDownIcon className="arrow-down-icon" />
                                {errors.lang && touched.lang && (
                                    <small className="text-danger">{errors.lang}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <label>{t('security_code')}</label>
                                <input type="text" className="form-control cus-security" placeholder={t('security_code')} onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.securityCode}
                                    name="securityCode" />
                                {errors.securityCode && touched.securityCode && (
                                    <small className="text-danger">{errors.securityCode}</small>
                                )}
                            </div>

                            <button type="button" className="btn btn-primary btn-block btn-lg mb-2" onClick={() => {
                                formik.handleSubmit();
                            }}>{t('Send')}</button>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}
export default Register;
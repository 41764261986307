export const getToken = (key) => localStorage.getItem(key);

export const validateToken = () => {
  const token = getToken("login-auth-token");
  const isLoggedIn = getToken("isLoggedIn");
 
  if (token && isLoggedIn) {
    // console.log(isLoggedIn,"isLoggedIn util",token)
    return true;
  }
  return false;
};

export const setToken = (key, token) => Promise.resolve().then(() => {
  localStorage.setItem(key, token);
});




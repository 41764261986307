const LOGIN = {
  LOGIN_INITLIZATION: "LOGIN_INITLIZATION",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERORR: "LOGIN_ERORR"
};

const LOGOUT = {
  LOGOUT_INITLIZATION: "LOGOUT_INITLIZATION",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERORR: "LOGOUT_ERORR"
};

const USER ={
  USER_INITLIZATION: "USER_INITLIZATION",
  USER_SUCCESS: "USER_SUCCESS",
  USER_ERORR: "USER_ERORR"
}

const SHIPMENTCOUNT ={
  SHIPMENTCOUNT_INITLIZATION: "SHIPMENTCOUNT_INITLIZATION",
  SHIPMENTCOUNT_SUCCESS: "SHIPMENTCOUNT_SUCCESS",
  SHIPMENTCOUNT_ERORR: "SHIPMENTCOUNT_ERORR"
}

const SHIPMENTLIST ={
  SHIPMENTLIST_INITLIZATION: "SHIPMENTLIST_INITLIZATION",
  SHIPMENTLIST_SUCCESS: "SHIPMENTLIST_SUCCESS",
  SHIPMENTLIST_ERORR: "SHIPMENTLIST_ERORR"
}

const SHIPMENTDETAILS ={
  SHIPMENTDETAILS_INITLIZATION: "SHIPMENTDETAILS_INITLIZATION",
  SHIPMENTDETAILS_SUCCESS: "SHIPMENTDETAILS_SUCCESS",
  SHIPMENTDETAILS_ERORR: "SHIPMENTDETAILS_ERORR"
}

const INVOICECOUNT ={
  INVOICECOUNT_INITLIZATION: "INVOICECOUNT_INITLIZATION",
  INVOICECOUNT_SUCCESS: "INVOICECOUNT_SUCCESS",
  INVOICECOUNT_ERORR: "INVOICECOUNT_ERORR"
}

const INVOICELIST = {
  INVOICELIST_INITLIZATION: "INVOICELIST_INITLIZATION",
  INVOICELIST_SUCCESS: "INVOICELIST_SUCCESS",
  INVOICELIST_ERORR: "INVOICELIST_ERORR"
}

const INVOICEPDF = {
  INVOICEPDF_INITLIZATION: "INVOICEPDF_INITLIZATION",
  INVOICEPDF_SUCCESS: "INVOICEPDF_SUCCESS",
  INVOICEPDF_ERORR: "INVOICEPDF_ERORR"
}

const QUOTATIONCOUNT ={
  QUOTATIONCOUNT_INITLIZATION: "QUOTATIONCOUNT_INITLIZATION",
  QUOTATIONCOUNT_SUCCESS: "QUOTATIONCOUNT_SUCCESS",
  QUOTATIONCOUNT_ERORR: "QUOTATIONCOUNT_ERORR"
}

const QUOTATIONLIST ={
  QUOTATIONLIST_INITLIZATION: "QUOTATIONLIST_INITLIZATION",
  QUOTATIONLIST_SUCCESS: "QUOTATIONLIST_SUCCESS",
  QUOTATIONLIST_ERROR: "QUOTATIONLIST_ERORR"
}

const SHIPMENTPREVIEWDETAILS ={
  SHIPMENTPREVIEWDETAILS_INITLIZATION: "SHIPMENTPREVIEWDETAILS_INITLIZATION",
  SHIPMENTPREVIEWDETAILS_SUCCESS: "SHIPMENTPREVIEWDETAILS_SUCCESS",
  SHIPMENTPREVIEWDETAILS_ERORR: "SHIPMENTPREVIEWDETAILS_ERORR"
}

const SHIPMENTSTATUS ={
  SHIPMENTSTATUS_INITLIZATION: "SHIPMENTSTATUS_INITLIZATION",
  SHIPMENTSTATUS_SUCCESS: "SHIPMENTSTATUS_SUCCESS",
  SHIPMENTSTATUS_ERORR: "SHIPMENTSTATUS_ERORR"
}

const COMPANYPROFILE ={
  COMPANYPROFILE_INITLIZATION: "COMPANYPROFILE_INITLIZATION",
  COMPANYPROFILE_SUCCESS: "COMPANYPROFILE_SUCCESS",
  COMPANYPROFILE_ERORR: "COMPANYPROFILE_ERORR"
}

const COMPANYLIST ={
  COMPANYLIST_INITLIZATION: "COMPANYLIST_INITLIZATION",
  COMPANYLIST_SUCCESS: "COMPANYLIST_SUCCESS",
  COMPANYLIST_ERORR: "COMPANYLIST_ERORR"
}

const UPDATECOMPANY ={
  UPDATECOMPANY_INITIALITATION: "UPDATECOMPANY_INITIALITATION",
  UPDATECOMPANY_SUCCESS: "UPDATECOMPANY_SUCCESS",
  UPDATECOMPANY_ERROR: "UPDATECOMPANY_ERROR"
}

const PROFILEIMG={
  PROFILEIMG_UPLOAD: "PROFILEIMG_UPLOAD",
}

const LOGOIMG={
  LOGOIMG_UPLOAD_INITIALIZATION: "LOGOIMG_UPLOAD_INITIALIZATION",
  LOGOIMG_UPLOAD_SUCCESS: "LOGOIMG_UPLOAD_SUCCESS",
  LOGOIMG_UPLOAD_ERROR: "LOGOIMG_UPLOAD_ERROR",
}

const GENERALSEARCH ={
  GENERALSEARCH_INITLIZATION: "GENERALSEARCH_INITLIZATION",
  GENERALSEARCH_SUCCESS: "GENERALSEARCH_SUCCESS",
  GENERALSEARCH_ERORR: "GENERALSEARCH_ERORR"
}

const COMPANYUSERS ={
  COMPANYUSERS_INITIALIZATION: "COMPANYUSERS_INITLIZATION",
  COMPANYUSERS_SUCCESS: "COMPANYUSERS_SUCCESS",
  COMPANYUSERS_ERORR: "COMPANYUSERS_ERORR"
}

const USERSDETAILS ={
  USERSDETAILS_INITLIZATION: "USERSDETAILS_INITLIZATION",
  USERSDETAILS_SUCCESS: "USERSDETAILS_SUCCESS",
  USERSDETAILS_ERORR: "USERSDETAILS_ERORR"
}

const INVOICEFILTER ={
  INVOICEFILTER_INITLIZATION: "INVOICEFILTER_INITLIZATION",
  INVOICEFILTER_SUCCESS: "INVOICEFILTER_SUCCESS",
  INVOICEFILTER_ERORR: "INVOICEFILTER_ERORR"
} 

const INVOICESTATUS ={
  INVOICESTATUS_INITLIZATION: "INVOICESTATUS_INITLIZATION",
  INVOICESTATUS_SUCCESS: "INVOICESTATUS_SUCCESS",
  INVOICESTATUS_ERORR: "INVOICESTATUS_ERORR"
}

const CREATEUSERS ={
  CREATEUSERS_INITLIZATION: "CREATEUSERS_INITLIZATION",
  CREATEUSERS_SUCCESS: "CREATEUSERS_SUCCESS",
  CREATEUSERS_ERORR: "CREATEUSERS_ERORR"
} 

const UPDATEUSERS ={
  UPDATEUSERS_INITLIZATION: "UPDATEUSERS_INITLIZATION",
  UPDATEUSERS_SUCCESS: "UPDATEUSERS_SUCCESS",
  UPDATEUSERS_ERORR: "UPDATEUSERS_ERORR"
} 

const QUOTATIONFILTER ={
  QUOTATIONFILTER_INITIALIZATION: "QUOTATIONFILTER_INITIALIZATION",
  QUOTATIONFILTER_SUCCESS: "QUOTATIONFILTER_SUCCESS",
  QUOTATIONFILTER_ERROR: "QUOTATIONFILTER_ERORR"
}

const QUOTATIONSTATUS ={
  QUOTATIONSTATUS_INITIALIZATION: "QUOTATIONSTATUS_INITLIZATION",
  QUOTATIONSTATUS_SUCCESS: "QUOTATIONSTATUS_SUCCESS",
  QUOTATIONSTATUS_ERORR: "QUOTATIONSTATUS_ERORR"
}

const NOTIFICATIONCMP ={
  NOTIFICATIONCMP_INITLIZATION: "NOTIFICATIONCMP_INITLIZATION",
  NOTIFICATIONCMP_SUCCESS: "NOTIFICATIONCMP_SUCCESS",
  NOTIFICATIONCMP_ERORR: "NOTIFICATIONCMP_ERORR"
}

const ORGANIZATIONDROPDOWN={
  ORGANIZATIONDROPDOWN_INITLIZATION: "ORGANIZATIONDROPDOWN_INITLIZATION",
  ORGANIZATIONDROPDOWN_SUCCESS: "ORGANIZATIONDROPDOWN_SUCCESS",
  ORGANIZATIONDROPDOWN_ERORR: "ORGANIZATIONDROPDOWN_ERORR"
}

const COUNTRYFLAG={
  COUNTRYFLAG_INITLIZATION: "COUNTRYFLAG_INITLIZATION",
  COUNTRYFLAG_SUCCESS: "COUNTRYFLAG_SUCCESS",
  COUNTRYFLAG_ERORR: "COUNTRYFLAG_ERORR"
} 
const NOTIFICATIONLIST={
  NOTIFICATIONLIST_INITLIZATION: "NOTIFICATIONLIST_INITLIZATION",
  NOTIFICATIONLIST_SUCCESS: "NOTIFICATIONLIST_SUCCESS",
  NOTIFICATIONLIST_ERORR: "NOTIFICATIONLIST_ERORR"
} 
const SENDINVITATION={
  SENDINVITATION_INITLIZATION: "SENDINVITATION_INITLIZATION",
  SENDINVITATION_SUCCESS: "SENDINVITATION_SUCCESS",
  SENDINVITATION_ERORR: "SENDINVITATION_ERORR"
} 

const REGISTER_USER={
  REGISTER_USER_INITLIZATION: "REGISTER_USER_INITLIZATION",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_ERORR: "REGISTER_USER_ERORR"
}

const DELETE_ORG_USER ={
  DELETE_ORG_USER_INITLIZATION: "DELETE_ORG_USER_INITLIZATION",
  DELETE_ORG_USER_SUCCESS: "DELETE_ORG_USER_SUCCESS",
  DELETE_ORG_USER_ERORR: "DELETE_ORG_USER_ERORR"
}

const MODIFY_USER ={
  MODIFY_USER_INITLIZATION: "MODIFY_USER_INITLIZATION",
  MODIFY_USER_SUCCESS: "MODIFY_USER_SUCCESS",
  MODIFY_USER_ERROR: "MODIFY_USER_ERORR"
}

const COMPANY_PHNO ={
  COMPANY_PHNO_INITLIZATION: "COMPANY_PHNO_INITLIZATION",
  COMPANY_PHNO_SUCCESS: "COMPANY_PHNO_SUCCESS",
  COMPANY_PHNO_ERORR: "COMPANY_PHNO_ERORR"
}

const FORGOT_PASSWORD ={
  FORGOT_PASSWORD_INITLIZATION: "FORGOT_PASSWORD_INITLIZATION",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERORR: "FORGOT_PASSWORD_ERORR"
} 

const RESET_PASSWORD ={
  RESET_PASSWORD_INITLIZATION: "RESET_PASSWORD_INITLIZATION",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERORR: "RESET_PASSWORD_ERORR"
} 
const NOTIFICATION_UPLOAD ={
  NOTIFICATION_UPLOAD_INITLIZATION: "NOTIFICATION_UPLOAD_INITLIZATION",
  NOTIFICATION_UPLOAD_SUCCESS: "NOTIFICATION_UPLOAD_SUCCESS",
  NOTIFICATION_UPLOAD_ERORR: "NOTIFICATION_UPLOAD_ERORR"
}
const NOTIFICATION_DETAILS ={
  NOTIFICATION_DETAILS_INITLIZATION: "NOTIFICATION_DETAILS_INITLIZATION",
  NOTIFICATION_DETAILS_SUCCESS: "NOTIFICATION_DETAILS_SUCCESS",
  NOTIFICATION_DETAILS_ERORR: "NOTIFICATION_DETAILS_ERORR"
}

const CHECKNOTIFICATION ={
  CHECKNOTIFICATION_INITZIALITZATION:"CHECKNOTIFICATION_INITZIALITZATION",
  CHECKNOTIFICATION_SUCCESS:"CHECKNOTIFICATION_SUCCESS",
  CHECKNOTIFICATION_ERROR:"CHECKNOTIFICATION_ERROR"
}

const ATTACHMENTPDF = {
  ATTACHMENTPDF_INITLIZATION: "ATTACHMENTPDF_INITLIZATION",
  ATTACHMENTPDF_SUCCESS: "ATTACHMENTPDF_SUCCESS",
  ATTACHMENTPDF_ERORR: "ATTACHMENTPDF_ERORR"
}

export { LOGIN , USER, GENERALSEARCH, SHIPMENTCOUNT, SHIPMENTLIST, SHIPMENTDETAILS, SHIPMENTPREVIEWDETAILS, COMPANYLIST,
  INVOICECOUNT, INVOICELIST, INVOICESTATUS, INVOICEPDF, QUOTATIONCOUNT, QUOTATIONLIST, SHIPMENTSTATUS, COMPANYPROFILE, PROFILEIMG, LOGOIMG,
  LOGOUT,COMPANYUSERS,USERSDETAILS, INVOICEFILTER, CREATEUSERS, UPDATEUSERS, QUOTATIONFILTER, QUOTATIONSTATUS,
  NOTIFICATIONCMP, ORGANIZATIONDROPDOWN, COUNTRYFLAG, NOTIFICATIONLIST, SENDINVITATION,
  REGISTER_USER, DELETE_ORG_USER, MODIFY_USER,COMPANY_PHNO,FORGOT_PASSWORD, RESET_PASSWORD,
  NOTIFICATION_UPLOAD, NOTIFICATION_DETAILS, CHECKNOTIFICATION, UPDATECOMPANY, ATTACHMENTPDF};


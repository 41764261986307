import React from 'react';
import { useFormik } from "formik";
import MainLogo from "../../components/logos/MainLogo";
import { ResetPasswordSchema } from "../../validationSchema/validationSchema";
import { resetPassword } from "../../redux/action";
import { useDispatch } from 'react-redux';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

export const ResetPassword = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword:"",
            securityCode:""
        },
        validationSchema: ResetPasswordSchema,
        onSubmit: (values) => {
            dispatch(resetPassword(values.password,values.securityCode));
        }
    }); 
    const { errors, touched } = formik;
    const {t} = useTranslation()

    return (
        <React.Fragment>
            <div className="main-container d-flex align-items-center justify-content-center">
                <div className="login-box">
                    <div className="login-logo">
                        {/*<img src={LogoWhite} alt="Logo" height={32} />*/}
                        <MainLogo/>
                    </div>
                    <div className="login-box-body">
                        <h1 className="text-center mb-3 font-weight-600">{t('Reset_Password')}</h1>
                        <form>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder={t('Password')} onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    name="password"
                                    id="txtPass" autoComplete="false" />

                                {errors.password && touched.password && (
                                    <small className="text-danger">{errors.password}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder={t('Confirm_password')} onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword}
                                    name="confirmPassword"
                                    id="cPass" autoComplete="false" />
                                {errors.confirmPassword && touched.confirmPassword && (
                                    <small className="text-danger">{errors.confirmPassword}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t('security_code')} onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.securityCode}
                                    name="securityCode"
                                    id="securityCode" />
                                {errors.securityCode && touched.securityCode && (
                                    <small className="text-danger">{errors.securityCode}</small>
                                )}
                            </div>
                            <button type="button" className="btn btn-primary btn-block btn-lg" onClick={() => {
                                formik.handleSubmit()
                                history.push({pathname: "/"})
                            }}>{t('Submit')}</button>
                        </form>
                    </div>
                   
                </div>
            </div>
            
        </React.Fragment>
   )

 }
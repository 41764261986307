import React from 'react';
import './App.css';
import RoutesComp from "./routes";
import DOMPurify from 'dompurify';
import {useGetThemeSettingsQuery} from "./redux/queryAPI/customerWebsiteAPI";
import LoadingSpinner from "./assets/css/spinners/LoadingSpinner";

function App() {
    // Fetch theme settings
    const { data, isLoading, isError, error } = useGetThemeSettingsQuery();

    // Handle loading and error states
    if (isLoading) {
        console.log('Loading...')
        return <LoadingSpinner />;
    }

    if (isError) {
        console.log('Error status:', error.status);
        console.log('Error response data:', error.data);
        console.log('Error message:', error.message);
        return <div className="container">Oops! An error occurred. Please try again later.</div>;
    }

    // Generate the theme style using the color from fetched data
    const generateThemeStyle = (colorCode) => `
        :root {
            --body-color: ${colorCode};
        }
    `;

    // Sanitize the theme style using DOMPurify
    const sanitizedThemeStyle = DOMPurify.sanitize(generateThemeStyle(data.colorCode));

    // Set the sanitized theme style as inner HTML of a <style> tag
    const createStyleTag = (content) => ({ __html: content });

    return (
        <>
            <style dangerouslySetInnerHTML={createStyleTag(sanitizedThemeStyle)} />
            <RoutesComp />
        </>
    );
}

export default App;
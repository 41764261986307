import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {NeedHelpBox} from "../../components";
import {Card, Breadcrumb, Button, Row, Col, Form, Modal} from "react-bootstrap";
import {ReactComponent as BackIcon} from '../../assets/img/back.svg';
import {useTranslation} from "react-i18next";
import {
    useAcceptQuotationMutation,
    useFetchQuotationQuery,
    useRejectQuotationMutation
} from "../../redux/queryAPI/customerWebsiteAPI";
import LoadingSpinner from "../../assets/css/spinners/LoadingSpinner";


const QuotationInfo = () => {
    const history = useHistory()

    const quotationId = history?.location?.id;
    const handleRedirect = () => {
        history.push({
            pathname: "/quotations"
        })
    }

    const [acceptQuotation] = useAcceptQuotationMutation();
    const [rejectQuotation] = useRejectQuotationMutation();
    const {data, error, isLoading} = useFetchQuotationQuery(quotationId);

    const [Reject, setReject] = useState(false);
    const [Accept, setAccept] = useState(false);

    const handleCloseReject = () => setReject(false);
    const handleReject = async () => {
        await rejectQuotation(quotationId);
        setReject(false);
    };
    const handleCloseAccept = async () => {
        const data = {
            "quotationId": quotationId,
            "message": "Hola"
        };

        await acceptQuotation(data);
        setAccept(false);
    };

    const handleAccept = () => setAccept(true);

    const {t} = useTranslation()

    if (isLoading){
        return <LoadingSpinner />;
    }

    if (error){
        return <div>Error!</div>
    }



  return (
    <React.Fragment>
      <div className="content">
        <Breadcrumb className='hot-shipment-action'>
          <Breadcrumb.Item className='mr-3' onClick={handleRedirect}> <BackIcon className="back-icon" /> </Breadcrumb.Item>
          <Breadcrumb.Item active> {t('Quotation')} </Breadcrumb.Item>
        </Breadcrumb>
        <section className="page-content container-fluid">
          <h1 className="page-title">{t('Quotation')} : {history?.location?.id}</h1>
          <Row className='form-row'>
            <Col className='col-lg-12'>
              <Card>
                <Card.Header className='bg-primary-custom py-2 pl-5 min-h-35'>
                  <h4 className='text-white m-0'>{t('Quotation_details')}</h4>
                </Card.Header>
                <Card.Body>
                  {/* <p className="text-primary-custom font-weight-bold"> Please provide any additional information you want to include in the offer. </p> */}
                  <Row className='form-row my-xl-4'>
                    <Col xl={8}>
                      <div className="pdf-iframe px-md-3">
                        {  console.log(history?.location?.url) }
                        <iframe src={history?.location?.url} type="application/pdf" width="100%" frameBorder="0" scrolling="auto" height="600"  title="quotationInfo"/>
                      </div>
                      {data.status === 'PENDING' &&

                          <div className="mt-3">
                            <Button type="button" variant="primary"
                                    onClick={handleAccept}>{t('Accept')}</Button>
                            <Button type="button" variant="secondary" onClick={handleReject}
                                    className="m-l-10">{t('Reject')}</Button>
                          </div>
                      }

                      {data.status === 'ACCEPTED' &&
                          <h3>
                            La oferta ha sido aceptada.
                          </h3>
                      }

                      {data.status === 'REJECTED' &&
                          <h3>
                            La oferta ha sido rechazada. Pendiente de recibir información.
                          </h3>
                      }
                    </Col>
                    <Col xl={4} className="border-left border-primary mt-2 mt-xl-0">
                      <Card.Body className="pl-xl-4 ml-xl-2">
                        <Row className="m-b-10 font-size-13 form-row">
                          <Col xs={3} className="text-primary-custom font-weight-700 text-right">
                            {t('Number')}
                          </Col>
                          <Col xs={9}>
                              {data.number}
                          </Col>
                        </Row>
                        <Row className="m-b-10 font-size-13 form-row">
                          <Col xs={3} className="text-primary-custom font-weight-700 text-right">
                            {t('Date')}
                          </Col>
                          <Col xs={9}>
                              {data.validUntil}
                          </Col>
                        </Row>
                        <Row className="m-b-10 font-size-13 form-row">
                          <Col xs={3} className="text-primary-custom font-weight-700 text-right">
                            {t('Quoted_to')}
                          </Col>
                          <Col xs={9}>
                              {data.billTo}
                          </Col>
                        </Row>
                        <Row className="m-b-10 font-size-13 form-row">
                          <Col xs={3} className="text-primary-custom font-weight-700 text-right">
                            {t('Quoted_by')}
                          </Col>
                          <Col xs={9}>
                              {data.billFrom}
                          </Col>
                        </Row>
                        <div className="my-3 my-lg-5">
                          <Form.Group className="additional-details mb-4" controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="text-primary-custom">{t('Aditional_details')}</Form.Label>
                            <Form.Control as="textarea" rows={6} />
                          </Form.Group>
                          <div className="text-right">
                            {/* <Button type="button" variant="secondary">Reject</Button> */}
                            <Button type="button" variant="primary" className="m-l-10">{t('Send')}</Button>
                            {/* <Button type="button" variant="primary" className="m-l-10">Accept</Button> */}
                          </div>
                        </div>
                          {data?.comments && data?.comments?.map((i, index) => (
                        <div className="d-flex mb-lg-5">
                          <div className="flex-shrink-0 mr-3">
                            <div className="user-name">{i?.userCode}</div>
                          </div>
                                <div className="flex-grow-1 font-size-15 text-black ms-2">
                                <span className="text-primary-custom d-block mb-1">
                                 User {i?.userName} on {i?.date}  commented: </span>
                                    {i?.text}
                            </div>
                        </div>
                          ))}
                      </Card.Body>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='form-row'>
            <Col xl={12}>
              <Row className='form-row'>
                <Col md={4} className="ml-auto">
                  <NeedHelpBox />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </div>

            <Modal centered scrollable size="lg" show={Reject} onHide={handleCloseReject}
                   className="warning-modal"
                   animation={false}>
                <Modal.Header className="align-items-center">
                    <h3 className="modal-title">{t('Reject')}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p>La oferta va a ser rechazada.</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button variant="primary" onClick={handleReject}>
                        Rechazar
                    </Button>
                    <Button variant="secondary" onClick={handleCloseReject}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal centered scrollable size="lg" show={Accept} onHide={handleCloseAccept}
                   className="warning-modal"
                   animation={false}>
                <Modal.Header className="align-items-center">
                    <h3 className="modal-title">Accept Lorem ipsum</h3>
                </Modal.Header>
                <Modal.Body>
                    <p>¿Aceptar la oferta?</p>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button variant="primary" onClick={handleCloseAccept}>
                        Aceptar
                    </Button>
                    <Button variant="primary" onClick={handleCloseAccept}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

        </React.Fragment>


    )
}

export default QuotationInfo;

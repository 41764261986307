import {createSlice} from '@reduxjs/toolkit'


const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        quotationFilters: {
            status: '',
            fromdate: '',
            todate: '',
            text: '',
            fromrecord: 0,
            numberofrecords: 5
        }
    },
    reducers: {
        setQuotationFilters: (state, action) => {
            state.quotationFilters = action.payload;
        },
        resetQuotationFilters: (state , action) => {
            state.quotationFilters = {
                status: '',
                fromdate: '',
                todate: '',
                text: '',
                fromrecord: 0,
                numberofrecords: 5
                }
            }
        }
    });

export const {setQuotationFilters, resetQuotationFilters} = filtersSlice.actions
export default filtersSlice.reducer
import {createSlice} from '@reduxjs/toolkit'


const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        homeFilters: {
            text: '',
            flow: '',
            transport: '',
            fromdate: '',
            todate: '',
            status: '',
            fromrecord: 0,
            numberofrecords: 10,
            currentpage: 1
        }

    },
    reducers: {
        setShipmentFilters: (state, action) => {
            state.homeFilters = action.payload;
        },
        resetShipmentFilters: (state, action) => {
            state.homeFilters = {
                text: '',
                flow: '',
                transport: '',
                fromdate: '',
                todate: '',
                status: '',
                fromrecord: 0,
                numberofrecords: 10,
                currentpage: 1
            }
        }
    }
});

export const {setShipmentFilters, resetShipmentFilters} = filtersSlice.actions
export default filtersSlice.reducer
/* eslint-disable no-case-declarations */
import {
    SHIPMENTCOUNT,
    SHIPMENTLIST,
    SHIPMENTDETAILS,
    SHIPMENTPREVIEWDETAILS,
    SHIPMENTSTATUS,
    GENERALSEARCH,
    NOTIFICATIONCMP,
    COUNTRYFLAG,
    NOTIFICATIONLIST
} from "../constants/action-types";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {ATTACHMENTPDF} from "../constants/action-types";

const initialState = {
    progress: false,
    isLoading: false,
    shipCount: 0,
    shipListDtl: [],
    allShipList: [],
    shipPreviewDtl: {},
    statusList: {},
    grlSearch: [],
    notificationColumnList: [],
    countryData: [],
    notificationList: [],
    AttachmentPDFDA:[]
};

const reducer = persistReducer(
    {storage, key: "basecode-demo", whitelist: ["authToken"]}, (state = initialState, action) => {
        switch (action.type) {
            case SHIPMENTCOUNT.SHIPMENTCOUNT_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };
            case SHIPMENTCOUNT.SHIPMENTCOUNT_SUCCESS:
                const shipCount = action.payload;
                return {
                    ...state,
                    isLoading: false,
                    shipCount: shipCount
                };
            case SHIPMENTCOUNT.SHIPMENTCOUNT_ERORR:
                return {
                    ...state,
                    isLoading: false
                };
            case SHIPMENTLIST.SHIPMENTLIST_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case SHIPMENTLIST.SHIPMENTLIST_SUCCESS:
                const allShip = action.payload;
                return {
                    ...state,
                    isLoading: false,
                    allShipList: allShip
                };

            case SHIPMENTLIST.SHIPMENTLIST_ERORR:
                return {
                    ...state,
                    isLoading: false
                };

            case SHIPMENTDETAILS.SHIPMENTDETAILS_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case SHIPMENTDETAILS.SHIPMENTDETAILS_SUCCESS:
                const shipDtl = action.payload;
                return {
                    ...state,
                    isLoading: false,
                    shipListDtl: shipDtl
                };

            case SHIPMENTDETAILS.SHIPMENTDETAILS_ERORR:
                return {
                    ...state,
                    isLoading: false
                };

            case SHIPMENTPREVIEWDETAILS.SHIPMENTPREVIEWDETAILS_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case SHIPMENTPREVIEWDETAILS.SHIPMENTPREVIEWDETAILS_SUCCESS:
                const shipPreDtl = action.payload;
                return {
                    ...state,
                    isLoading: false,
                    shipPreviewDtl: shipPreDtl
                };

            case SHIPMENTPREVIEWDETAILS.SHIPMENTPREVIEWDETAILS_ERORR:
                return {
                    ...state,
                    isLoading: false
                };

            case SHIPMENTSTATUS.SHIPMENTSTATUS_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case SHIPMENTSTATUS.SHIPMENTSTATUS_SUCCESS:
                const status = action.payload;
                // console.log(status,"111111");
                return {
                    ...state,
                    isLoading: false,
                    statusList: status
                };

            case SHIPMENTSTATUS.SHIPMENTSTATUS_ERORR:
                return {
                    ...state,
                    isLoading: false
                };

            //gENERAL SEARCH
            case GENERALSEARCH.GENERALSEARCH_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case GENERALSEARCH.GENERALSEARCH_SUCCESS:
                const gSearch = action.payload;
                // console.log(status,"111111");
                return {
                    ...state,
                    isLoading: false,
                    grlSearch: gSearch
                };

            case GENERALSEARCH.GENERALSEARCH_ERORR:
                return {
                    ...state,
                    isLoading: false
                };

            //notification comp
            case NOTIFICATIONCMP.NOTIFICATIONCMP_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case NOTIFICATIONCMP.NOTIFICATIONCMP_SUCCESS:
                const notification = action.payload;
                return {
                    ...state,
                    isLoading: false,
                    notificationColumnList: notification
                };

            case NOTIFICATIONCMP.NOTIFICATIONCMP_ERORR:
                return {
                    ...state,
                    isLoading: false
                };

            //COUNTRYFLAG
            case COUNTRYFLAG.COUNTRYFLAG_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case COUNTRYFLAG.COUNTRYFLAG_SUCCESS:
                const FLAG = action.payload;
                return {
                    ...state,
                    isLoading: false,
                    countryData: FLAG
                };

            case COUNTRYFLAG.COUNTRYFLAG_ERORR:
                return {
                    ...state,
                    isLoading: false
                };
            //NOTIFICATIONLIST
            case NOTIFICATIONLIST.NOTIFICATIONLIST_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case NOTIFICATIONLIST.NOTIFICATIONLIST_SUCCESS:
                const noti = action.payload;
                return {
                    ...state,
                    isLoading: false,
                    notificationList: noti
                };

            case NOTIFICATIONLIST.NOTIFICATIONLIST_ERORR:
                return {
                    ...state,
                    isLoading: false
                };

            case ATTACHMENTPDF.ATTACHMENTPDF_INITLIZATION:
                return {
                    ...state,
                    isLoading: true
                };

            case ATTACHMENTPDF.ATTACHMENTPDF_SUCCESS:
                const Attachmentpdf = action.payload;
                return {
                    ...state,
                    isLoading: false,
                    AttachmentPDFDA: Attachmentpdf
                };

            case ATTACHMENTPDF.ATTACHMENTPDF_ERORR:
                return {
                    ...state,
                    isLoading: false
                };
            default:
                return state;
        }
    }
);
export default reducer;

import React, {useEffect} from "react";
import {useFormik} from "formik";
import {Link} from 'react-router-dom';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LoginSchema} from "../../validationSchema/validationSchema";
//redux
import {login} from "../../redux/action";
import {useTranslation} from "react-i18next";
import MainLogo from "../../components/logos/MainLogo";

const SignIn = () => {
    const {push} = useHistory();
    const dispatch = useDispatch();

    const isLoggedin = useSelector(
        (state) => state.userDataReducer.isLoggedin
    );

    const isLoggedout = useSelector(
        (state) => state.userDataReducer.isLoggedout
    );

    useEffect(() => {
        if (isLoggedin === true && isLoggedout === false) {
            push('/home');
        } else {
            push("/")
        }
    }, [isLoggedin, isLoggedout, push]);

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            dispatch(login(values.username, values.password));
        },
    });

    const {errors, touched} = formik;
    const {t} = useTranslation()

    return (
        <React.Fragment>
            <div className="main-container d-flex align-items-center justify-content-center">
                <div className="login-box">
                    <div className="login-logo">
                        <MainLogo/>
                        {/*<img src={Logo} onChange={} alt="Logo" height={32}/>*/}
                    </div>
                    <div className="login-box-body">
                        <h1 className="text-center mb-3 font-weight-600">{t('LogIn')}</h1>
                        <p className="text-center mb-4">{t('Enter_your_credentials_to_access_the_panel')}</p>
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t('Email')}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.username}
                                       name="username"
                                       id="txtUserName"/>
                                {errors.username && touched.username && (
                                    <small className="text-danger" id="errUserName">{errors.username}</small>
                                )}
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder={t('Password')}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.password}
                                       name="password"
                                       id="txtPass" autoComplete="false"/>
                                {errors.password && touched.password && (
                                    <small className="text-danger">{errors.password}</small>
                                )}
                            </div>
                            <button type="button" className="btn btn-primary btn-block btn-lg" onClick={() => {
                                formik.handleSubmit();
                            }}>{t('LogIn')}
                            </button>
                        </form>
                    </div>
                    <div className="login-box-footer d-flex align-items-center flex-wrap justify-content-between">
                        <div className="form-group mb-0">
                            <label className="control control-outline control-primary control--checkbox m-0"
                                   htmlFor="as">{t('RememberMe' +
                                '')}
                                <input type="checkbox" id="as"/>
                                <div className="control__indicator"></div>
                            </label>
                        </div>
                        <div className="password-reset-link">
                            <Link className="text-primary-custom" to="/forgot-password"> {t('Forgot_password')}</Link>
                        </div>
                    </div>
                    <div className="login-box-footer align-items-center">
                        <small>V {process.env.REACT_APP_VERSION}</small>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SignIn;

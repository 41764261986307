import * as Yup from "yup";

const SignupSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match",
  ),
});


const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Username is Required"),
  password: Yup.string().required("Password is Required."),
});

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required").max(25,'First Name cannot be more than 25 characters')
  .matches(/^[a-z\d\-'.\s]+$/i,
    "First Name can have alphanumeric, spaces & .-' only"
  ),
  lastName: Yup.string().required("Last Name is required").max(25,'Last Name cannot be more than 25 characters')
  .matches(/^[a-z\d\-'.\s]+$/i,
    "Last Name can have alphanumeric, spaces & .-' only"
  ),
  mobileNumber: Yup.string().required("mobile number is required").min(9,'mobile number is Invalid'),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6).required("Password is Required."),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password ? field.required().oneOf([Yup.ref('password')]) : field
  ),
  DateF:Yup.string().required("Date Format is required"),
  lang:Yup.string().required("Language Format is required"),
  securityCode:Yup.string().required("Security Code is required"),
});
const ModifyUserSchema = Yup.object().shape({
  id:Yup.string().required("Required"),
  firstName: Yup.string().required("First Name is required").max(25,'First Name cannot be more than 25 characters')
  .matches(/^[a-z\d\-'.\s]+$/i,
    "First Name can have alphanumeric, spaces & .-' only"
  ),
  lastName: Yup.string().required("Last Name is required").max(25,'Last Name cannot be more than 25 characters')
  .matches(/^[a-z\d\-'.\s]+$/i,
    "Last Name can have alphanumeric, spaces & .-' only"
  ),
  phone: Yup.string().required("mobile number is required"),
  email: Yup.string().email("Invalid email").required("Required"),
  
  dataFormat:Yup.string().required("Date Format is required"),
  language:Yup.string().required("Language Format is required")
});
const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});
const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(6).required("Password is Required."),
  confirmPassword: Yup.string().when('password', (password, field) =>
    password ? field.required().oneOf([Yup.ref('password')]) : field
  ),
  securityCode:Yup.string().required("Security Code is required"),
});

const notificationUploadSchema = Yup.object().shape({
  description:Yup.string().required("Description Code is required"),
  summary:Yup.string().required("Summary is required")
});
export {
  SignupSchema,
  LoginSchema,
  RegistrationSchema,
  ModifyUserSchema,
  ForgotPasswordSchema,
  ResetPasswordSchema,
  notificationUploadSchema
};

import React from 'react';
import Pdf from "react-to-pdf";
import PlayForwardingLogo from "../../assets/img/play-forwarding.png";
import QRCode from "../../assets/img/qr-code.png";
import { Button } from "react-bootstrap";

const ref = React.createRef();

const PDF = () => {
  return (
    <>
      <div className='post' ref={ref}>
        <div className="pdf-container" >
          <div className="pdf-wrap" >
            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" >
              <tbody>
                <tr>
                  <td width="40%">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                      <tbody>
                        <tr>
                          <td>
                            <img src={PlayForwardingLogo} alt="Play Forwarding" width={90} />
                          </td>
                          <td>
                            PLAYFORWARDING MARSEILLE <br />
                            23 PL. DE LA JOLIETTE, <br />
                            13226 MARSEILLE <br />
                            FRANCE <br />
                            V64330855
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="60%">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                      <tbody>
                        <tr>
                          <td width="120" style={{ paddingRight: '0' }}>
                            <img src={QRCode} alt="QR Code" width={110} height={110} />
                          </td>
                          <td>
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                              <tbody>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Offer Num.
                                  </td>
                                  <td align="center">788</td>
                                </tr>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Offer date
                                  </td>
                                  <td align="center">16/03/2022</td>
                                </tr>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Valid from
                                  </td>
                                  <td align="center">16/03/2022</td>
                                </tr>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Valid to
                                  </td>
                                  <td align="center">16/03/2022</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td width="50%" style={{ paddingBottom: '0' }}>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                      <tbody>
                        <tr>
                          <td>
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                              <tbody>
                                <tr>
                                  <td align="right" width="120" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Company:
                                  </td>
                                  <td>CHEMICAL TRADING EGYPY </td>
                                </tr>
                                <tr style={{ border: 'none', borderSpacing: '0', borderCollapse: 'collapse'  }}>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff', border: 'none', borderSpacing: '0', borderCollapse: 'collapse' }}>
                                    Contact Name:
                                  </td>
                                  <td>NISHAL RAMPERSAD</td>
                                </tr>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff', border: 'none', borderSpacing: '0', borderCollapse: 'collapse' }}>
                                    Email:
                                  </td>
                                  <td>limeteammrs@playforwarding.com</td>
                                </tr>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff', border: 'none', borderSpacing: '0', borderCollapse: 'collapse' }}>
                                    Phone:
                                  </td>
                                  <td>0661258227</td>
                                </tr>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Transport Mode:
                                  </td>
                                  <td>Seafrieght Export FCL</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="50%" style={{ paddingBottom: '0', verticalAlign: 'bottom' }}>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                      <tbody>
                        <tr>
                          <td>
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                              <tbody>
                                <tr>
                                  <td align="right" width="120" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Sales Represent:
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Email :
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Phone :
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td align="right" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Incoterm :
                                  </td>
                                  <td>CIP</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                      <tbody>
                        <tr>
                          <td>
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                              <tbody>
                                <tr>
                                  <td align="right" width="180" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Dangerous Goods:
                                  </td>
                                  <td>
                                    <div className="pdf-checkbox checked" style={{ backgroundColor: '#ebf0f1', border: '1px solid #404141' }}></div>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="right" width="180" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Description of Goods:
                                  </td>
                                  <td style={{ whiteSpace: 'normal' }}>HUMIDIFIER WITH REMOTE. Packages: 504. Gross Weight: 2,520 kgs. Volume: 30.4 cbm. Chargeable weight: </td>
                                </tr>
                                <tr>
                                  <td align="right" width="180" style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Customer Reference:
                                  </td>
                                  <td>VT 20025</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                      <tbody>
                        <tr>
                          <td>
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center">
                              <tbody>
                                <tr>
                                  <td align="center" colSpan={9} style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    FULL CONTAINER SEAFREIGHT
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Port of Origin
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff', whiteSpace: 'nowrap' }}>
                                    Port of Destination
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Service
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Item
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff', whiteSpace: 'nowrap' }}>

                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff' }} align="center">
                                    Qty
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff', whiteSpace: 'nowrap' }} align="right">
                                    Price
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff', whiteSpace: 'nowrap' }} align="right">
                                    Amount
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff', whiteSpace: 'nowrap' }} align="right">
                                    Subtotal
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    MARSEILLE
                                  </td>
                                  <td>
                                    DUMYAT
                                  </td>
                                  <td style={{ whiteSpace: 'nowrap' }}>
                                    Direct, 8 Days
                                  </td>
                                  <td>
                                    FREIGHT
                                  </td>
                                  <td style={{ whiteSpace: 'nowrap' }}>
                                    40' DRY
                                  </td>
                                  <td align="center">
                                    1
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    873,20 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    873,20 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    873,20 €
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2} align="center">Service: SDG LINES</td>
                                  <td colSpan={7}></td>
                                </tr>
                                <tr>
                                  <td colSpan={3}></td>
                                  <td>
                                    BAF
                                  </td>
                                  <td style={{ whiteSpace: 'nowrap' }}>
                                    40' DRY
                                  </td>
                                  <td align="center">
                                    1
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    200,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    200,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    200,00 €
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={8} align="right">FREIGHT Subtotal in EUR</td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">1.073,20 €</td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr>
                                  <td align="center" colSpan={9} style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    FOB CHARGES
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff' }}>
                                    Invoice Concept
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff' }} colSpan={3}></td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff', whiteSpace: 'nowrap' }}></td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff' }} align="center">
                                    Qty
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff', whiteSpace: 'nowrap' }} align="right">
                                    Price
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff', whiteSpace: 'nowrap' }} align="right">
                                    Amount
                                  </td>
                                  <td style={{ backgroundColor: '#98c154', color: '#fff', whiteSpace: 'nowrap' }} align="right">
                                    Subtotal
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    PICKUP FEE
                                  </td>
                                  <td colSpan={3}></td>
                                  <td style={{ whiteSpace: 'nowrap' }}></td>
                                  <td align="center">
                                    1
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    1.770,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    1.770,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    1.770,00 €
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    DOCUMENTATION
                                  </td>
                                  <td colSpan={3}></td>
                                  <td style={{ whiteSpace: 'nowrap' }}>
                                    40' DRY
                                  </td>
                                  <td align="center">
                                    1
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    40,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    40,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    40,00 €
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    CFS
                                  </td>
                                  <td colSpan={3}></td>
                                  <td style={{ whiteSpace: 'nowrap' }}>
                                    40' DRY
                                  </td>
                                  <td align="center">
                                    1
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    15,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    15,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    15,00 €
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    DOCUMENTATION
                                  </td>
                                  <td colSpan={3}></td>
                                  <td style={{ whiteSpace: 'nowrap' }}>40' DRY</td>
                                  <td align="center">
                                    1
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    15,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    15,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    15,00 €
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ whiteSpace: 'nowrap' }}>
                                    GOODS INSURANCE MANAGEMENT
                                  </td>
                                  <td colSpan={3}></td>
                                  <td style={{ whiteSpace: 'nowrap' }}></td>
                                  <td align="center">
                                    1
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    671,50 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    671,50 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    671,50 €
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    VGM SOLAS
                                  </td>
                                  <td colSpan={3}></td>
                                  <td style={{ whiteSpace: 'nowrap' }}>
                                    40' DRY
                                  </td>
                                  <td align="center">
                                    1
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    20,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    20,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    20,00 €
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    PORT TAXES
                                  </td>
                                  <td colSpan={3}></td>
                                  <td style={{ whiteSpace: 'nowrap' }}>
                                    40' DRY
                                  </td>
                                  <td align="center">
                                    1
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    25,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    25,00 €
                                  </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">
                                    25,00 €
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={8} align="right">Subtotal in EUR</td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">2.541,50 €</td>
                                </tr>
                                <tr>
                                  <td colSpan={9} height="5"></td>
                                </tr>
                                <tr>
                                  <td rowSpan={3}>Payment cond. for SERVICES: <br /> 
                                      BANK TRANSFER. 30 days due date <br /> 
                                      Payment cond. for VAT and DUTY: <br />
                                      BANK TRANSFER:
                                  </td>
                                  <td colSpan={7} align="right">FREIGHT Subtotal in EUR: </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">1.073,20 €</td>
                                </tr>
                                <tr>
                                  <td colSpan={7} align="right">Expenses Subtotal in EUR: </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">2.541,50 €</td>
                                </tr>
                                <tr>
                                  <td colSpan={7} align="right">TOTAL AMOUNT: </td>
                                  <td style={{whiteSpace: 'nowrap'}} align="right">3.614,70 €</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="position-relative text-center mt-4">
        <Button type="button" variant="link" className="pr-next-btn m-r-10"><i className="icon dripicons-chevron-left text-primary-custom font-size-18 top-2 m-0"></i> </Button>
        <Button type="button" variant="link" className="zoom-btn m-r-10"><i className="icon dripicons-plus text-primary-custom font-size-16 m-0"></i> </Button>
        <Button type="button" variant="link" className="zoom-btn mr-2"><i className="icon dripicons-minus text-primary-custom font-size-16 m-0"></i> </Button>
        <Button type="button" variant="link" className="pr-next-btn"><i className="icon dripicons-chevron-right text-primary-custom font-size-18 top-2 m-0"></i></Button>
        <Pdf targetRef={ref} filename="post.pdf">
          {({ toPdf }) => <button onClick={toPdf} className="btn btn-primary download-btn ml-auto">Download</button>}
        </Pdf>
      </div>
    </>
  );
}

export default PDF;
import React, {useEffect, useState, useRef} from "react";
import {Card, Breadcrumb, Row, Col, Table, Form, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {ReactComponent as BackIcon} from '../../assets/img/back.svg'
import {ReactComponent as SortIcon} from '../../assets/img/sort.svg'
import {ReactComponent as SortPrimaryIcon} from '../../assets/img/sort-primary.svg'
import {ReactComponent as TotalInvoiceIcon} from '../../assets/img/total-invoice.svg'
import {ReactComponent as OverdueIcon} from '../../assets/img/overdue.svg'
import {ReactComponent as PaidIcon} from '../../assets/img/paid.svg'
import {ReactComponent as UnPaidIcon} from '../../assets/img/unpaid.svg'
import {ReactComponent as SearchIcon} from "../../assets/img/search-icon.svg";
import {NeedHelpBox} from "../../components";

import {FaFilePdf} from 'react-icons/fa';
import {useHistory} from "react-router-dom";

//redux
import {useSelector} from "react-redux";
import {dispatch} from "../../redux";
import {
    invoiceCount,
    invoiceList,
    invoicePDFDnld,
    invoicePDFSuccess,
    invoiceStatus
} from "../../redux/action";
import {useTranslation} from 'react-i18next';
import cookies from 'js-cookie';
import {INVOICEPDF} from "../../redux/constants/action-types";
import DatePicker from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import {resetInvoiceFilters, setInvoiceFilters} from "../../redux/reducers/invoiceFiltersReducer";
import Pagination from "react-bootstrap/Pagination";
import {toast} from "react-toastify";
import axios from "../../service/axiosConfig";

const Invoice = (props) => {
    const filters = useSelector((state) => state?.invoiceFiltersReducer?.invoiceFilters)
    const sessionsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [sortType, setSortType] = useState(false);
    const [countData, setCountData] = useState(null);
    const [listData, setListData] = useState([]);
    const [pdfData, setPDFData] = useState('');
    const [searchtext, setsearchText] = useState('');
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [status, setStatus] = useState({});
    const [type, setType] = useState('');
    const [fromRecord, setFromRecord] = useState(0);
    const [statusFilter, setStatusFilter] = useState('');

    const InvoiceCount = useSelector((state) => state?.invoiceReducer?.invCount);
    const invoiceListData = useSelector((state) => state?.invoiceReducer?.allInvoiceList);
    const invoicePDFData = useSelector((state) => state?.invoiceReducer?.InvoicePDFDA);
    const invoiceFilterData = useSelector((state) => state?.invoiceReducer?.InvoiceFilterDA);
    const InvoiceStatusData = useSelector((state) => state?.invoiceReducer?.statusList);

    const history = useHistory()
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const {t} = useTranslation()

    const pagesCount = Math.ceil(countData / 5);
    const isPaginationShown = true;
    const isCurrentPageFirst = currentPage === 1;
    const isCurrentPageLast = currentPage === pagesCount;
    let isPageNumberOutOfRange;


    const changePage = number => {
        if (currentPage === number) return;
        setCurrentPage(number);
        dispatch(setInvoiceFilters({...filters, fromrecord: (currentPage - 1) * sessionsPerPage}))
    };

    const onPageNumberClick = pageNumber => {
        changePage(pageNumber);
        dispatch(setInvoiceFilters({...filters, fromrecord: (pageNumber - 1) * sessionsPerPage}))
    };

    const onPreviousPageClick = () => {
        changePage(currentPage => currentPage - 1);
        dispatch(setInvoiceFilters({...filters, fromrecord: (currentPage - 2) * sessionsPerPage}))
    };

    const onNextPageClick = () => {
        changePage(currentPage => currentPage + 1);
        dispatch(setInvoiceFilters({...filters, fromrecord: currentPage * sessionsPerPage}))
    };

    const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === pagesCount;
        const isCurrentPageWithinTwoPageNumbers =
            Math.abs(pageNumber - currentPage) <= 2;

        if (
            isPageNumberFirst ||
            isPageNumberLast ||
            isCurrentPageWithinTwoPageNumbers
        ) {
            isPageNumberOutOfRange = false;
            return (
                <Pagination.Item
                    key={pageNumber}
                    onClick={() => onPageNumberClick(pageNumber)}
                    active={pageNumber === currentPage}
                >
                    {pageNumber}
                </Pagination.Item>
            );
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted"/>;
        }

        return null;
    });

    useEffect(() => {
        setCountData(InvoiceCount);
    }, [InvoiceCount]);

    useEffect(() => {
        dispatch(invoiceCount(filters));
        dispatch(invoiceList(filters));
        dispatch(invoiceStatus(filters));
    }, [filters]);

    useEffect(() => {
        if (invoiceFilterData) {
            dispatch(setInvoiceFilters({...filters, invoiceFilterData: invoiceFilterData, fromrecord: 0}))
        }
    }, [invoiceFilterData])

    useEffect(() => {
        if (type) {
            dispatch(setInvoiceFilters({...filters, type: type, fromrecord: 0}))
        }
    }, [type]);

    useEffect(() => {
        if (fromDate) {
            let date = JSON.stringify(fromDate)
            dispatch(setInvoiceFilters({...filters, fromdate: date, fromrecord: 0}))
            setCurrentPage(1);
        }
    }, [fromDate]);

    useEffect(() => {
        if (toDate) {
            let date = JSON.stringify(toDate)
            dispatch(setInvoiceFilters({...filters, todate: date, fromrecord: 0}))
            setCurrentPage(1);
        }
    }, [toDate]);

    useEffect(() => {
        if (statusFilter) {
            dispatch(setInvoiceFilters({...filters, status: statusFilter, fromrecord: 0}))
            setCurrentPage(1);
        }
    }, [statusFilter]);

    useEffect(() => {
        if (invoiceListData) {
            setListData(invoiceListData);
        }
    }, [invoiceListData]);

    useEffect(() => {
        if (InvoiceStatusData) {
            setStatus(InvoiceStatusData)
        }
    }, [InvoiceStatusData])

    useEffect(() => {
        document.body.dir = currentLanguageCode.dir || 'ltr'
    }, [currentLanguageCode, t])

    const handleSort = (sortName) => {
        setSortType(!sortType)

        if (sortType === false) {

            listData.sort(function (a, b) {
                if (a[sortName] < b[sortName]) return 1;
                if (a[sortName] > b[sortName]) return -1;
                return 0;
            });
            return listData;
        } else {

            listData.sort(function (a, b) {
                if (a[sortName] < b[sortName]) return -1;
                if (a[sortName] > b[sortName]) return 1;
                return 0;
            });
            return listData;
        }
    }


    const handlePreview = (id, name) => {

        axios.get(process.env.REACT_APP_BASE_API_CALL + `invoice/${id}`, {
            responseType: 'blob'
        })
            .then(response => {
                const blobURL = window.URL.createObjectURL(response.data);
                const aTag = document.createElement("a");
                aTag.href = blobURL;
                aTag.setAttribute("download", name + ".pdf");
                document.body.appendChild(aTag);
                aTag.click();
                aTag.remove();
            }).catch((error) => {
            toast.error('Se ha producido un error al descargar el archivo', {
                position: "top-right",
                autoClose: 300,
                hideProgressBar: false,
                closeOnClick: true,
                theme: "light",
            });
        })
    };

//Date Handlers

    const startRefTo = useRef();
    const startRefFrom = useRef();

    const handleKeyDownTo = () => {
        if (startRefFrom?.current?.getElementsByClassName("rmdp-calendar")?.length !== 0) {
            const valRef = startRefFrom?.current?.getElementsByClassName("rmdp-calendar");
            valRef[0].classList.add("HIDE");
        }
    };

    const handleKey = () => {
        if (startRefTo?.current?.getElementsByClassName("rmdp-calendar")?.length !== 0) {
            const valRefTo = startRefTo?.current?.getElementsByClassName("rmdp-calendar");
            valRefTo[0].classList.add("HIDE");
        }

    }
    const handleOpenFrom = () => {
        if (startRefFrom?.current?.getElementsByClassName("HIDE")) {
            const valRefHide = startRefFrom?.current?.getElementsByClassName("rmdp-calendar");
            valRefHide[0].classList.remove("HIDE");
        }
    }

    const handleOpenTo = () => {
        if (startRefTo?.current?.getElementsByClassName("HIDE")) {
            const valRefHideTo = startRefTo?.current?.getElementsByClassName("rmdp-calendar");
            valRefHideTo[0].classList.remove("HIDE");
        }
    }

    const handleSearch = (text) => {
        setsearchText(text);
        dispatch(setInvoiceFilters({...filters, text: text}))
        setCurrentPage(1);
    }

    const handleRefresh = () => {
        dispatch(resetInvoiceFilters());
        setStatusFilter('')
        setSortType('');
        setsearchText('');
        setType('');
        setFromDate('');
        setToDate('');
        setCurrentPage(1);
        setFromRecord(0);
    }

    const handleStatus = (e) => {
        setStatusFilter(e)
    }

    return (
        <React.Fragment>
            <div className="content">
                <Breadcrumb className='hot-shipment-action'>
                    <Breadcrumb.Item className='mr-3' onClick={history.goBack}> <BackIcon className="back-icon"/>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <section className="page-content container-fluid">
                    <h1 className="page-title">{t('Invoices')}</h1>
                    <Row className='form-row'>
                        <Col xl={9}>
                            <Card>
                                <Card.Body>
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="options"
                                        defaultValue=""
                                        value={statusFilter}
                                        onChange={(e) => handleStatus(e)}
                                        className="dashboard-filter invoice-filter flex-wrap"
                                    >
                                        <ToggleButton id="tbg-radio-1" variant="" value="" onClick={handleRefresh}>
                                            <div className="d-flex align-items-center">
                                                <div className="icon ml-2">
                                                    <TotalInvoiceIcon/>
                                                </div>
                                                <div className="d-flex flex-column flex-fill text-center ml-3">
                                                    <p className="font-size-16 text-primary-custom  font-weight-bold m-1">{t('Total')}</p>
                                                    <h2 className='font-size-60 lh-58 mb-0'>{status.totalInvoices}</h2>
                                                    <p className="font-size-16 text-primary-custom  font-weight-bold m-0">{t('Invoices')}</p>
                                                </div>
                                            </div>
                                            {/*<div className="my-3">*/}
                                            {/*    <ProgressBar variant="primary" now={75} />*/}
                                            {/*</div>*/}
                                            {/*<div className="text-center">*/}
                                            {/*    <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Total')}</p>*/}
                                            {/*    /!*<h4 className="h2 m-0">{filterStatus.totalInvoicesTotalAmount}</h4>*!/*/}
                                            {/*</div>*/}
                                        </ToggleButton>
                                        <ToggleButton id="tbg-radio-2" variant="" value="OVERDUE">
                                            <div className="d-flex align-items-center">
                                                <div className="icon ml-2">
                                                    <OverdueIcon/>
                                                </div>
                                                <div className="d-flex flex-column flex-fill text-center ml-3">
                                                    <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Overdue')}</p>
                                                    <h2 className='font-size-60 lh-58 mb-0'>{status.overdue}</h2>
                                                    <p className="font-size-16 text-primary-custom font-weight-bold m-0">{t('Invoices')}</p>
                                                </div>
                                            </div>
                                            {/*<div className="my-3">*/}
                                            {/*    /!*<ProgressBar variant="primary" now={30} />*!/*/}
                                            {/*</div>*/}
                                            {/*<div className="text-center">*/}
                                            {/*    <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Overdue')}</p>*/}
                                            {/*    /!*<h4 className="h2 m-0">{filterStatus.overdueInvoicesTotalAmount}</h4>*!/*/}
                                            {/*</div>*/}
                                        </ToggleButton>
                                        <ToggleButton id="tbg-radio-4" variant="" value='PENDING'>
                                            <div className="d-flex align-items-center">
                                                <div className="icon ml-2">
                                                    <UnPaidIcon/>
                                                </div>
                                                <div className="d-flex flex-column flex-fill text-center ml-3">
                                                    <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Pending')}</p>
                                                    <h2 className='font-size-60 lh-58 mb-0'>{status.unpaid}</h2>
                                                    <p className="font-size-16 text-primary-custom font-weight-bold m-0">{t('Invoices')}</p>
                                                </div>
                                            </div>
                                            {/*<div className="my-3">*/}
                                            {/*    /!*<ProgressBar variant="primary" now={20} />*!/*/}
                                            {/*</div>*/}
                                            {/*<div className="text-center">*/}
                                            {/*    <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Pending')}</p>*/}
                                            {/*    /!*<h4 className="h2 m-0">{filterStatus.unpaidInvoicesTotalAmount}</h4>*!/*/}
                                            {/*</div>*/}
                                        </ToggleButton>
                                        <ToggleButton id="tbg-radio-3" variant="" value="PAID">
                                            <div className="d-flex align-items-center">
                                                <div className="icon ml-2">
                                                    <PaidIcon/>
                                                </div>
                                                <div className="d-flex flex-column flex-fill text-center ml-3">
                                                    <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Paid')}</p>
                                                    <h2 className='font-size-60 lh-58 mb-0'>{status.paid}</h2>
                                                    <p className="font-size-16 text-primary-custom font-weight-bold m-0">{t('Invoices')}</p>
                                                </div>
                                            </div>
                                            {/*<div className="my-3">*/}
                                            {/*    /!*<ProgressBar variant="primary" now={50} />*!/*/}
                                            {/*</div>*/}
                                            {/*<div className="text-center">*/}
                                            {/*    <p className="font-size-16 text-primary-custom font-weight-bold mb-1">{t('Paid')}</p>*/}
                                            {/*    /!*<h4 className="h2 m-0">{filterStatus.paidInvoicesTotalAmount}</h4>*!/*/}
                                            {/*</div>*/}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    {/*<Card.Header className='border-0 px-0'>*/}
                                    {/*    <h3 className='mb-0'>Invoices List</h3>*/}
                                    {/*</Card.Header>*/}
                                    <Card.Body className='p-0'>
                                        <Card className='mb-0'>
                                            <div className='card-body'>
                                                <Row className='mb-3 align-items-center'>
                                                    <Col lg={8}>
                                                        <div className='table-top-select'>
                                                            <div className='arrow-down d-inline-block mr-2 mr-lg-3'>
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <div
                                                                        className="date-picker mb-2 mb-sm-0 mr-2 mr-lg-3"
                                                                        onClick={handleOpenFrom}>
                                  <span className="icon">
                                    <i className="zmdi zmdi-calendar"></i>
                                  </span>
                                                                        <DatePicker value={fromDate}
                                                                                    onChange={setFromDate}
                                                                                    inputClass="form-control"
                                                                                    placeholder={t('From')}
                                                                                    format="DD/MM/YYYY"
                                                                                    ref={startRefFrom}/>
                                                                    </div>
                                                                    <div className="date-picker mb-2 mb-sm-0"
                                                                         onClick={handleOpenTo}>
                                  <span className="icon">
                                    <i className="zmdi zmdi-calendar"></i>
                                  </span>
                                                                        <DatePicker value={toDate} onChange={setToDate}
                                                                                    ref={startRefTo}
                                                                                    onOpen={(e) => handleKeyDownTo(e)}
                                                                                    inputClass="form-control"
                                                                                    placeholder={t('To')}
                                                                                    format="DD/MM/YYYY"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*<div className='arrow-down d-inline-block'>*/}
                                                            {/*    <Form.Control as="select" onChange={(e) => setType(e.target.value)} value={type}>*/}
                                                            {/*        <option >{t('Invoice_Type')}</option>*/}
                                                            {/*        <option value='SALE'>{t('Sale')}</option>*/}
                                                            {/*        <option value='PREINVOICE'>{t('Preinvoice')}</option>*/}
                                                            {/*        <option value='RECTIFICATIVE'>{t('Rectificative')}</option>*/}
                                                            {/*    </Form.Control>*/}
                                                            {/*    <ArrowDownIcon className="arrow-down-icon" />*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} className='d-flex justify-content-end mt-2 mt-lg-0'>
                                                        <div className="table-top-search d-flex mr-3">
                                                            <SearchIcon className="icon"/>
                                                            <Form.Control type="text" placeholder={t('Search')}
                                                                          value={searchtext}
                                                                          onChange={(e) => handleSearch(e.target.value)}
                                                                          onFocus={handleKey}/>
                                                        </div>
                                                        <span className="refresh-icon" onClick={handleRefresh}>
                                                            <i className="zmdi zmdi-refresh font-size-18 align-middle"></i>
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Table responsive borderless hover>
                                                    <thead>
                                                    <tr>
                                                        <th>{t('Accounting_status')} <SortIcon
                                                            className='align-baseline'/></th>
                                                        <th className="text-primary-custom"
                                                            onClick={() => handleSort("number")}>{t('Number')}
                                                            <SortPrimaryIcon
                                                                className='sort-primary-icon align-baseline'/></th>
                                                        <th onClick={() => handleSort("shipment")}>{t('Shipment')}<SortIcon
                                                            className='align-baseline'/></th>
                                                        <th onClick={() => handleSort("date")}>{t('Date')} <SortIcon
                                                            className='align-baseline'/></th>
                                                        <th>{t('Due_date')} <SortIcon className='align-baseline'/></th>
                                                        {/*<th onClick={() => handleSort("type")} >{t('Invoice_Type')}<SortIcon className='align-baseline' /></th>*/}
                                                        {/*<th onClick={() => handleSort("baseExempt")} >{t('Base_Exempt')}<SortIcon className='align-baseline' /></th>*/}
                                                        {/*<th onClick={() => handleSort("taxAmount")} >{t('Tax')} <SortIcon className='align-baseline' /></th>*/}
                                                        <th onClick={() => handleSort("totalAmount")}>Total <SortIcon
                                                            className='align-baseline'/></th>
                                                        <th>{t('Download')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {listData &&
                                                        listData?.map((item, index) => (
                                                            <tr key={item?.id}>
                                                                <td>{item?.accountingStatus}</td>
                                                                <td>{item?.number}</td>
                                                                <td>{item?.shipment}</td>
                                                                <td>{item?.date}</td>
                                                                <td>{item?.dueDate}</td>
                                                                {/*<td>{item?.type}</td>*/}
                                                                {/*<td>{item.baseExempt} </td>*/}
                                                                {/*<td>{item.taxAmount}</td>*/}
                                                                <td>{item.totalAmount}</td>
                                                                <td className="actions-dropdown"
                                                                    onClick={() => handlePreview(item?.id, item?.number)}>
                                                                    <FaFilePdf className="iconPreview"/>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Card>
                                        <>
                                            {isPaginationShown && InvoiceCount > 0 && (
                                                <Pagination size="sm" className='pt-2 mt-4 justify-content-end'>
                                                    <Pagination.Prev
                                                        onClick={onPreviousPageClick}
                                                        disabled={isCurrentPageFirst}
                                                    />
                                                    {pageNumbers}
                                                    <Pagination.Next
                                                        onClick={onNextPageClick}
                                                        disabled={isCurrentPageLast}
                                                    />
                                                </Pagination>
                                            )}
                                        </>
                                    </Card.Body>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='form-row'>
                        <Col xl={10}>
                            <Row className='form-row'>
                                <Col md={4} className="ml-auto">
                                    <NeedHelpBox/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            </div>
        </React.Fragment>
    )
}

export default Invoice;

import React, {useEffect} from 'react'
import {Card, Breadcrumb, Row, Col} from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {ReactComponent as BackIcon} from '../../assets/img/back.svg';
import {NeedHelpBox, UserProfile, UserOrganization} from "../../components";
import {useTranslation} from 'react-i18next';
import cookies from 'js-cookie';

import {useHistory} from "react-router-dom";
import {useFetchUserProfileQuery} from "../../redux/queryAPI/customerWebsiteAPI";

const Settings = () => {
    const currentLanguageCode = cookies.get('i18next') || 'sp';
    const {t} = useTranslation()

    const history = useHistory()
    useEffect(() => {
        document.body.dir = currentLanguageCode.dir || 'ltr'
    }, [currentLanguageCode, t])

    const {data, error, isLoading} = useFetchUserProfileQuery();

    if (isLoading) return <div>Loading...</div>
    if (error) {
        return (
            <div>Error: {error}</div>
        )
    }

    return (
        <React.Fragment>
            <div className="content">
                <Breadcrumb className='hot-shipment-action'>
                    <Breadcrumb.Item className='mr-3' onClick={history.goBack}> <BackIcon className="back-icon"/>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <section className="page-content container-fluid">
                    <h1 className="page-title">{t('Settings')}</h1>
                    <Row className='form-row'>
                        <Col xl={10}>
                            <Card className="rounded-0">
                                <Card.Body className="common-details text-dark p-4">
                                    {data.role === 'SYSADMIN' ?
                                        <Tabs defaultActiveKey="user-profile"
                                              className="settings-tabs m-0 mb-2 mb-md-4" transition={false}>
                                            <Tab eventKey="user-profile" title={t('User_Profile')}>
                                                <UserProfile/>
                                            </Tab>
                                            {/*<Tab eventKey="company" title={t('Company')}>
                                                <Company/>
                                            </Tab>*/}
                                            <Tab eventKey="users-organizations"
                                                 title={t('Users_and_Organizations')}>
                                                <UserOrganization/>
                                            </Tab>

                                        </Tabs>
                                        :
                                        <Tabs defaultActiveKey="user-profile"
                                              className="settings-tabs m-0 mb-2 mb-md-4" transition={false}>
                                            <Tab eventKey="user-profile" title={t('User_Profile')}>
                                                <UserProfile/>
                                            </Tab>
                                        </Tabs>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='form-row'>
                        <Col xl={10}>
                            <Row className='form-row'>
                                <Col md={4} className="ml-auto">
                                    <NeedHelpBox/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            </div>
        </React.Fragment>
    )
}

export default Settings;